import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, of, switchMap } from "rxjs";
import { DashboardService } from "../../../features/dashboard/dashboard.service";
import { getDashboardMetrics, getDashboardMetricsClosed, getDashboardMetricsClosedFailure, getDashboardMetricsClosedSuccess, getDashboardMetricsEstimate, getDashboardMetricsEstimateFailure, getDashboardMetricsEstimateSuccess, getDashboardMetricsFailure, getDashboardMetricsProduction, getDashboardMetricsProductionFailure, getDashboardMetricsProductionSuccess, getDashboardMetricsSuccess, getDashboardShippingMap, getDashboardShippingMapFailure, getDashboardShippingMapSuccess } from "./dashboard.actions";
import { IDashboardMapResponse, IDashboardMetricsResponse } from "../../models/dashboard";

@Injectable()
export class DashboardEffects {
    constructor(
        private actions$: Actions,
        private dashboardService: DashboardService,
    ){}

    getDashboardMetricsEstimate$ = 
    createEffect( () =>
        this.actions$.pipe(
            ofType(getDashboardMetricsEstimate),
            switchMap((action) => this.dashboardService.getDashboardMetrics(action.payload.clientId, action.payload.type, action.payload.body)
                .pipe(
                    map((metricsResponse: IDashboardMetricsResponse) =>
                    getDashboardMetricsEstimateSuccess({ metricsResponse })
                    ),
                    catchError((error) => of(getDashboardMetricsEstimateFailure({ error })))
                )
            )
        )
    )
    
    getDashboardMetricsProduction$ = 
    createEffect( () =>
        this.actions$.pipe(
            ofType(getDashboardMetricsProduction),
            switchMap((action) => this.dashboardService.getDashboardMetrics(action.payload.clientId, action.payload.type, action.payload.body)
                .pipe(
                    map((metricsResponse: IDashboardMetricsResponse) =>
                    getDashboardMetricsProductionSuccess({ metricsResponse })
                    ),
                    catchError((error) => of(getDashboardMetricsProductionFailure({ error })))
                )
            )
        )
    )
    
    getDashboardMetricsClosed$ = 
    createEffect( () =>
        this.actions$.pipe(
            ofType(getDashboardMetricsClosed),
            switchMap((action) => this.dashboardService.getDashboardMetrics(action.payload.clientId, action.payload.type, action.payload.body)
                .pipe(
                    map((metricsResponse: IDashboardMetricsResponse) =>
                    getDashboardMetricsClosedSuccess({ metricsResponse })
                    ),
                    catchError((error) => of(getDashboardMetricsClosedFailure({ error })))
                )
            )
        )
    )
    getDashboardShippingMapMetrics$ = createEffect(() => 
        this.actions$.pipe(
            ofType(getDashboardShippingMap),
            switchMap((action) => this.dashboardService.getDashboardShippingMapMetrics(action.clientId)
                .pipe(
                    map((shippingMapResponse: [IDashboardMapResponse]) => getDashboardShippingMapSuccess({shippingMapResponse})),
                    catchError((error) => of(getDashboardShippingMapFailure({error})))
                ))
        )
    )
}