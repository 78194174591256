import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-about-stokkup',
  templateUrl: './about-stokkup.component.html',
  styleUrls: ['./about-stokkup.component.scss']
})
export class AboutStokkupComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
