import { Injectable } from "@angular/core";
import { SessionService } from "../../services/session/session.service";
import { getClient, getClientSuccess, getClientFailure, getClientContacts, getClientContactsSuccess, createClientContact, createClientContactSuccess, createClientContactFailure, getAvalaraClient, getAvalaraClientSuccess, getAvalaraClientFailure} from "src/app/core/state/client/client.actions";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, mergeMap, of, switchMap} from "rxjs";
import { GlobalService } from "../../services/api/global.service";
import { SharedService } from "src/app/shared/shared.service";
import { CatalogDesignService } from "@core/services/catalog/catalog-design.service";

@Injectable()
export class ClientEffects {

    getClientDecorator$ = createEffect( () => 
        this.actions$.pipe(
            ofType(getClient),
            mergeMap(() => 
                this.sessionService.getUserClient()
                .pipe(
                    map((client) => (getClientSuccess({client}))),
                    catchError((error) => of(getClientFailure(error)))
                )    
            )
        )
    )

    getClientContacts$ = createEffect(() => 
        this.actions$.pipe(
            ofType(getClientContacts),
            mergeMap((action) => 
                this.globalService.getClientContacts(action.clientId)
                .pipe(
                    map((clientContacts) => (getClientContactsSuccess({clientContacts}))),
                    catchError((error) => of(getClientFailure(error)))
                )
            )
        )
    )

    createClientContact$ = createEffect(() => 
        this.actions$.pipe(
            ofType(createClientContact),
            switchMap((action) => this.globalService.createClientContact(action.clientContact)
            .pipe(
                map((clientContact: any) => createClientContactSuccess({clientContact})),
                catchError((error) => of(createClientContactFailure({error})))
            ))
        )
    )

    createClientContactSuccessNotification$ = createEffect(() => 
                this.actions$.pipe(
                    ofType(createClientContactSuccess),
                    map(() => {
                        let message = "Success! New Collaborator has been added! "
                        let toastClass = 'bg-success';        
                        let notification = {data: {title: message, class: toastClass}, autohide: true}
                        this.sharedService.showNotification(notification);
                    })
                ),
                {dispatch: false}
    )
    
    getAvalaraClient$ = createEffect(() =>
    this.actions$.pipe(
        ofType(getAvalaraClient),
        switchMap((action) => this.catalogDesignService.getAvalaraCustomer(action.customerCode)
            .pipe(
                map((avalaraClient: any) => getAvalaraClientSuccess({avalaraClient})),
                catchError((error) => of(getAvalaraClientFailure({error})))
            )
        )
    )
    );


    constructor(
        private actions$: Actions,
        private sessionService: SessionService,
        private globalService: GlobalService,
        private sharedService: SharedService,
        private catalogDesignService: CatalogDesignService
    ){}

}