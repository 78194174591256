import { Injectable } from '@angular/core';
import { Router, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import * as UserActions from '@core/state/user/user.actions';
import * as fromUsers from '@core/state/user/user.selector';

@Injectable({
  providedIn: 'root'
})
export class UserDecoratorResolver  {
  constructor(private store: Store){}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    let ud;
    this.store.pipe(select(fromUsers.selectDecorator)).subscribe((userdecorator) => ud = userdecorator )
    return of(true);
  }
}
