<div class="modal-content">
  <div class="modal-header">
    <h6 class="modal-title">{{design.products.title}}</h6>
    <button type="button" class="btn-close" (click)="closeModal()"></button>
  </div>
  <div class="modal-body">
    <p class="fw-bold">Design: <span class="fw-normal">{{design.designName}}</span></p>
    <div class="row">
      <div class="col-12 overflow-scroll">
        <table class="table table-borderless">
          <thead class="table-light">
            <tr class="text-center">
              <th class="ps-1 pe-1">Color</th>
              <th *ngFor="let variant of colorSizes[0].variants; let i = index" class="ps-1 pe-1">{{variant.size}}
              </th>
            </tr>
          </thead>
          <ng-container *ngFor="let colorGroup of colorSizes">
            <tbody>
              <tr>
                <td class="qty-table text-center">
                  <span class="fw-normal">{{colorGroup.color | titlecase}}</span>
                </td>
                <td *ngFor="let variant of colorGroup.variants; let j = index" class="ps-1 pe-1">
                  <mdb-form-control class="qty-input">
                    <input mdbInput type="number" id="typeText" class="form-control" [value]="variant.qty"
                      (input)="onInputChanged($event, variant)" />
                  </mdb-form-control>
                </td>
              </tr>
            </tbody>
          </ng-container>
        </table>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-primary qty-close" (click)="closeModal()">Close</button>
    <button type="button" class="btn btn-primary qty-save" (click)="saveChanges()">Save Changes</button>
  </div>
</div>
