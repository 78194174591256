import { Component } from '@angular/core';
import { MdbNotificationRef } from 'mdb-angular-ui-kit/notification';

@Component({
  selector: 'app-toasts',
  templateUrl: './toasts.component.html',
  styleUrls: ['./toasts.component.scss']
})
export class ToastsComponent {
  message: string | null = null;
  title: string | null = null;
  class: string | null = null;
  constructor(public notificationRef: MdbNotificationRef<ToastsComponent>) { }
}