import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ErrorState } from '../../models/error-state';

@Injectable({
  providedIn: 'root'
})
export class ErrorsService {
  private _showError = new BehaviorSubject<boolean>(false)
  showError$: Observable<boolean> = this._showError.asObservable()
  private _errorState = new BehaviorSubject<ErrorState>({
    imageUrl: 'assets/images/404.jpg',
    title: 'Resource not found',
    message: `The resource you were looking for was not found`,
    routerLink: '/',
    buttonText: 'Go back home'
  })
  errorState$: Observable<ErrorState> = this._errorState.asObservable()

  constructor() { }

  showError() {
    this._showError.next(true)
  }

  hideError() {
    this._showError.next(false)
  }

  setErrorState(state?: ErrorState) {
    this._errorState.next(state)
  }
}
