import { Injectable } from "@angular/core";
import { Actions, concatLatestFrom, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, of, switchMap, from, tap } from "rxjs";
import { CatalogDesignService } from "@core/services/catalog/catalog-design.service";

import { getDesignLibrary, getDesignLibrarySuccess, getDesignLibraryFailure, getCanvasDimensionsSuccess, getCanvasDimensionsFailure, getCanvasDimensions, getClientDesignsSuccess, addDesign, addDesignSuccess, addDesignFailure, getDesignToolMockups, getDesignToolMockupsSuccess, getDesignToolMockupsFailure, getCatalogProductDetails, getCatalogProductDetailsSuccess, getCatalogProductDetailsFailure, updateDesinPositionObject, deleteDesignPositionObject, saveDesignPositionCanvasChangesSuccess, getClientDesigns as getClientDesignsAction, getClientDesignsFailure, updateDesignPositions, updateDesignPositionSuccess, updateDesignPositionFailure, getDesignToolCanvas, getDesignToolCanvasSuccess, getDesignToolCanvasFailure, getDesignToolCanvasAvailableSuccess, getDesignToolCanvasNotAvailableFailure, getDesignToolCanvasAvailable, removeFileFromDesignLibrary, removeFileFromDesignLibrarySuccess } from "@core/state/design-tool/design-tool.actions";
import { MongoDbService } from "@core/services/api/mongodb.service";
import { CatalogProductDetails } from "@core/models/products";
import { Store } from "@ngrx/store";
import {selectDesignObject } from "./design-tool.selectors";
import { SessionService } from "@core/services/session/session.service";
import { CanvasDimensions } from "@core/models/designTool";

@Injectable()
export class DesignToolEffects {
  constructor(
    private actions$: Actions,
    private catalogDesign: CatalogDesignService,
    private mongoService: MongoDbService,
    private session: SessionService,
    private store: Store,
  ) { }

  getDesignLibrary$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getDesignLibrary),
      switchMap((action) => this.catalogDesign.getDesignFilesFromS3(action.client?.id)
        .pipe(
          map((designLibraryResponse: any) =>
            getDesignLibrarySuccess({ designLibraryResponse })
          ),
          catchError((error) => of(getDesignLibraryFailure({ error })))
        )
      )
    )
  );

  removeFileFromDesignLibrary$ = createEffect(() =>
    this.actions$.pipe(
      ofType(removeFileFromDesignLibrary),
      switchMap((action) => this.catalogDesign.removeFileFromDesignFiles(action.fileName, this.session.getActiveClient())
        .pipe(
          tap((resp: boolean) =>
            this.store.dispatch(getDesignLibrary({client: this.session.getActiveClient()}))
          ),
          map((resp) => removeFileFromDesignLibrarySuccess({resp})),
          catchError((error) => of(getDesignLibraryFailure({ error })))
        )
      )
    )
  );

  getDesignToolCanvas$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getDesignToolCanvas),
      switchMap((action) => this.catalogDesign.getDesignToolCanvas(action.data)
        .pipe(
          map((designToolCanvasResponse: any) => getDesignToolCanvasSuccess({ designToolCanvasResponse })),
          catchError((error) => of(getDesignToolCanvasFailure({ error })))
        )
    )
  ));

  getDesignToolCanvasAvailable = createEffect(() =>
    this.actions$.pipe(
      ofType(getDesignToolCanvasAvailable),
      switchMap((action) => this.catalogDesign.getDesignToolCanvasAvailable()
      .pipe(
        map((designToolCanvasAvailable: any) => getDesignToolCanvasAvailableSuccess({designToolCanvasAvailable}) ),
        catchError((error) => of(getDesignToolCanvasNotAvailableFailure({ error })))
      ))
  ));

  getDesignToolMockup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getDesignToolMockups),
      switchMap((product) => this.catalogDesign.getDesignToolMockups(product)
        .pipe(
          map((mockupImagesResponse: any) => getDesignToolMockupsSuccess({ mockupImagesResponse })),
          catchError((error) => of(getDesignToolMockupsFailure({ error })))
        )
      )
    )
  );

  getCanvasDimensions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getCanvasDimensions),
      switchMap((action) => of(this.catalogDesign.isProductCustomizable(action.brand, action.style, action.color, [] ))
        .pipe(
          map((canvasDimensions: CanvasDimensions) =>
            getCanvasDimensionsSuccess({ canvasDimensions })
          ),
          catchError((error) => of(getCanvasDimensionsFailure({ error })))

        )
      )
    )
  );

  getClientDesigns$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getClientDesignsAction),
      switchMap((action) => this.catalogDesign.getDesignsByClient(action.client?.id)
        .pipe(
          map((clientDesignsResponse: any) => getClientDesignsSuccess({ clientDesignsResponse })),
          catchError((error) => of(getClientDesignsFailure({ error })))
        )
      )
    )
  );

  getCatalogProductDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getCatalogProductDetails),
      switchMap((params) => this.catalogDesign.getCatalogProductDetails(params.brand, params.style)
        .pipe(
          map((product: CatalogProductDetails) => getCatalogProductDetailsSuccess({ product })),
          catchError((error) => of(getCatalogProductDetailsFailure({ error })))
        )
      )
    )
  );

  // add Cart from Mongo by clientId
  addDesign$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addDesign),
      switchMap((action) =>
        from(this.mongoService.addDesign(action.design)).pipe( // Convert to observable
          map((designResponse: any) =>
            addDesignSuccess({ designResponse })
          ),
          catchError((error) => of(addDesignFailure({ error }))
          )
        )
      ),
      tap(() => {
        this.store.dispatch(getClientDesignsAction({client: this.session.getActiveClient()}))
      })
    )
  );

  saveDesignPositionCanvasChanges$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteDesignPositionObject, updateDesinPositionObject),
      concatLatestFrom(() => this.store.select(selectDesignObject)),
      switchMap(([action, design]) => {
        return this.mongoService.updateDesignPositionCanvas(design._id, action.position, design.positions.find(e => e.position == action.position).canvas)
        .pipe(
          map(resp => saveDesignPositionCanvasChangesSuccess({position: action.position, design: design.positions.find(e => e.position == action.position).canvas})),
          catchError((error) => of(addDesignFailure({ error })))
        )
      })
    )
  );

  updateDesignPositionObject$ = createEffect(() =>
      this.actions$.pipe(
        ofType(updateDesignPositions),
        switchMap((action) => {
          return this.mongoService.updateDesignPositions(action.designId, action.positions)
          .pipe(
            map(response => updateDesignPositionSuccess({ response })),
            catchError((error) => of(updateDesignPositionFailure({ error })))
          )
        })
      )
    );
}
