import { NgModule } from '@angular/core';
import { ActionReducerMap, createFeatureSelector, createSelector, MetaReducer, State, StoreModule } from '@ngrx/store';
import * as fromUser from '@core/state/user/user.reducers';


/**
 * Feature Selector
 **/
 export const selectUserState = createFeatureSelector<fromUser.State>(fromUser.featureName);
 export const selectUser = createSelector(selectUserState, state => state.profile);
 export const selectDecorator = createSelector(selectUserState, state => state.decorator);
 export const selectClient = createSelector(selectUserState, state => state.client);
 export const selectClients = createSelector(selectUserState, state => state.clients);
 export const selectDecorators = createSelector(selectUserState, state => state.decorators);
 export const selectUserIsAffiliate = createSelector(selectUserState, (data) => {
    let isAffiliate : string | null;
    if(data){
        let profile = data.profile
        if(profile?.isAffiliateUser) {
            isAffiliate = profile.isAffiliateUser
        }
    }
    return isAffiliate;
 } )
export const selectClientId = createSelector(selectUserState, state => state.clientId)
export const selectDecoratorId = createSelector(selectUserState, state => state.decoratorId)

export const selectClientAMId = createSelector(selectClient, (data) => {
    let am: number | 0
    if(data){
        am = data.account_manager
    }
    return am
})
 
export const selectClientSalesId = createSelector(selectClient, (data) => {
    let sales: number | 0
    if(data){
        sales = data.salesperson
    }
    return sales
})

export const selectClientPriceGridId = createSelector(selectClient, (data) => {
    let priceGrid: number | 0
    if(data){
        priceGrid = data.salespricegrid
    }
    return priceGrid
})