import { Injectable } from "@angular/core";
import { Actions, concatLatestFrom, createEffect, ofType } from "@ngrx/effects";
import { AuthService } from "src/app/core/services/auth/auth.service";
import { catchError, of, map, tap, switchMap, mergeMap, firstValueFrom } from "rxjs";
import { ActivatedRoute, Router } from "@angular/router";
import { IAuthResponse } from "../../models/auth";
import { getAuthUser, getAuthUserSuccess, getAuthUserFailure, logoutUser, logoutUserSuccess, logoutUserFailure } from "src/app/core/state/auth/auth.actions";
import { Store } from "@ngrx/store";
import { SharedService } from "src/app/shared/shared.service";

import * as AuthActions from '@core/state/auth/auth.actions';
import * as UserActions from '@core/state/user/user.actions';
import * as routeSelector from '@core/state/router/router-state.selectors';
import * as fromUsers from '@core/state/user/user.selector';
import { IApiResponse } from "../../services/api/api-response";
import { SessionService } from "@core/services/session/session.service";

@Injectable()
export class AuthEffects {

    constructor(
        private actions$: Actions,
        private authService: AuthService,
        private router: Router,
        private store: Store,
        private sharedService: SharedService,
        private session: SessionService,
        private route: ActivatedRoute
    ) {}
    /**
     * on login button click - call auth/login api to authenticate
     */
    loginRequest$ = createEffect( () => 
        this.actions$.pipe(
            ofType(AuthActions.authLoginRequest),
            switchMap((action) => 
                this.authService.login(action.credentials)
                .pipe(
                    switchMap((loginSuccessResponse: IAuthResponse) => [
                        AuthActions.authLoginRequestSuccess({loginSuccessResponse}),
                        UserActions.getUserInfo()
                    ]),
                    // map((loginSuccessResponse: IAuthResponse) =>
                    //     AuthActions.authLoginRequestSuccess({loginSuccessResponse})
                    // ),
                    catchError((error) => of(AuthActions.authLoginRequestFailure({error})))
                )
            )
        )
    )

    confirmEmailRequest$ = createEffect(() => 
        this.actions$.pipe(
            ofType(AuthActions.confirmUser),
            switchMap((action) => this.authService.accountValidationToken(action.token)
            .pipe(
                map((response: IApiResponse) => 
                    AuthActions.confirmUserSuccess({response}),
                ),
                catchError((error) => of(AuthActions.confirmUserFailure({error})))
            ))
        )
    )

    confirmEmailRequestSuccess$ = createEffect( () =>
        this.actions$.pipe(
            ofType(AuthActions.confirmUserSuccess),
            concatLatestFrom(() => this.store.select(routeSelector.getDecoratorRoute)),
            tap(([action, state]) => {
                 
                this.router.navigate(['/login/cs'] )
            })
        ),            
        { dispatch: false } 
    )

    confirmEmailRequestFailure$ = createEffect( () =>
        this.actions$.pipe(
            ofType(AuthActions.confirmUserFailure),
            concatLatestFrom(() => this.store.select(routeSelector.getDecoratorRoute)),
            tap(([action, state]) => {
                 
                this.router.navigate(['/login/cs'] ) 
            })
        ),            
        { dispatch: false } 
    )

    resetUserPasswordRequest$ = createEffect(() => 
        this.actions$.pipe(
            ofType(AuthActions.resetPassword),
            switchMap((action) => this.authService.resetPassword({email: action.email})
            .pipe(
                map((response: IApiResponse) => 
                    AuthActions.resetPasswordSuccess({response}),
                ),
                catchError((error) => of(AuthActions.resetPasswordFailure({error})))
            ))
        )
    );

    resetUserPasswordRequestSuccess$ = createEffect( () => 
        this.actions$.pipe(
            ofType(AuthActions.resetPasswordSuccess),
            concatLatestFrom(() => this.store.select(routeSelector.getDecoratorRoute)),
            tap(([action, state]) => { 
                let message = "Success! Instructions to reset your Password have been sent your email"
                let toastClass = 'bg-success';        
                let notification = {data: {title: message, class: toastClass}, autohide: true}
                this.sharedService.showNotification(notification);
                this.router.navigate(['/login/cs']) 
            })
        ),
        { dispatch: false }
    )

    resetUserPasswordRequestFailure$ = createEffect( () => 
        this.actions$.pipe(
            ofType(AuthActions.resetPasswordFailure),
            concatLatestFrom(() => this.store.select(routeSelector.getDecoratorRoute)),
            tap(([action, state]) => {
                let message = "Error! Email not found, please reach out to your Account Manager for further assistance"
                let toastClass = 'bg-danger';        
                let notification = {data: {title: message, class: toastClass}, autohide: true}
                this.sharedService.showNotification(notification);
            })
        ),
        { dispatch: false }
    )

    submitResetUserPasswordRequest$ = createEffect(() => 
        this.actions$.pipe(
            ofType(AuthActions.submitResetPassword),
            switchMap((action) => this.authService.resetPasswordConfirmation(action.formData)
            .pipe(
                map((response: IApiResponse) => 
                    AuthActions.submitResetPasswordSuccess({response}),
                ),
                catchError((error) => of(AuthActions.submitResetPasswordFailure({error})))
            ))
        )
    );

    submitResetUserPasswordRequestSuccess$ = createEffect( () => 
        this.actions$.pipe(
            ofType(AuthActions.submitResetPasswordSuccess),
            concatLatestFrom(() => this.store.select(routeSelector.getDecoratorRoute)),
            tap(([action, state]) => {
                let message = "Success! Your Password has been reset!"
                let toastClass = 'bg-success';        
                let notification = {data: {title: message, class: toastClass}, autohide: true}
                this.sharedService.showNotification(notification);
                this.router.navigate(['/login/cs'] )
            })
        ),
        { dispatch: false }
    )

    submitResetUserPasswordRequestFailure$ = createEffect( () => 
        this.actions$.pipe(
            ofType(AuthActions.submitResetPasswordFailure),
            concatLatestFrom(() => this.store.select(routeSelector.getDecoratorRoute)),
            tap(([action, state]) => {
                let message = "Error! Password could not be reset, please reach out to your Account Manager for further assistance"
                let toastClass = 'bg-danger';        
                let notification = {data: {title: message, class: toastClass}, autohide: true}
                this.sharedService.showNotification(notification);
            })
        ),
        { dispatch: false }
    )

    /**
     * on login success: redirect to choose client
     */
    loginSuccess$ = createEffect( () =>
        this.actions$.pipe(
            ofType(AuthActions.authLoginRequestSuccess),
            concatLatestFrom(() => [this.store.select(routeSelector.getDecoratorRoute), this.store.select(fromUsers.selectDecorator)]),
            tap(async ([action, state, decorator]) => {
                this.session.setActiveDecorator(decorator);
                let is_affiliate: string | null = action.loginSuccessResponse.data.is_affiliate_user
                const queryParams = await firstValueFrom(this.route.queryParams)
                                
                if(is_affiliate == '1') {
                    this.router.navigate(['/catalog'], {queryParams})
                } else {
                    this.router.navigate(['/login/choose-client'], {queryParams})
                }
            })
        ),            
        { dispatch: false } 
    )
    /**
     * on login error: show notification error toast with the error message
     */
    loginError$ = createEffect( () => 
        this.actions$.pipe(
            ofType(AuthActions.authLoginRequestFailure),
            concatLatestFrom(() => this.store.select(routeSelector.getDecoratorRoute)),
            tap(([action, state]) => {
                let error = action.error['error']
                let toastClass = 'bg-danger';        
                let notification = {data: {title: error.message, class: toastClass}, autohide: true}
                this.sharedService.showNotification(notification);
            })
        ),
        { dispatch: false }
    )

     /**
     * on google login button click - call auth/login api to authenticate
     */
    googleLoginRequest$ = createEffect( () => 
        this.actions$.pipe(
            ofType(AuthActions.authGoogleLoginRequest),
            switchMap((action) => 
                this.authService.googleAuth(action.credentials)
                .pipe(
                    map((loginSuccessResponse: any) =>
                        AuthActions.authLoginRequestSuccess({loginSuccessResponse})
                        ),
                        catchError((error) => of(AuthActions.authLoginRequestFailure({error})))
                )
            )
        )
    )

    /**
     * on google login success: redirect to choose decorator
     */
    googleLoginSuccess$ = createEffect( () =>
        this.actions$.pipe(
            ofType(AuthActions.authGoogleLoginRequestSuccess),
            concatLatestFrom(() => this.store.select(routeSelector.getDecoratorRoute)),
            tap(([action, state]) => {
                 
                let is_affiliate: string | null = action.loginSuccessResponse.data.is_affiliate_user
                this.router.navigate(['/login/cs/choose-decorator'], { state: {is_affiliate} })
            })
        ),            
        { dispatch: false } 
    )

    /**
     * on login error: show notification error toast with the error message
     */
    googleLoginError$ = createEffect( () => 
        this.actions$.pipe(
            ofType(AuthActions.authGoogleLoginRequestFailure),
            concatLatestFrom(() => this.store.select(routeSelector.getDecoratorRoute)),
            tap(([action, state]) => {
                let error = action.error['error']
                let toastClass = 'bg-danger';        
                let notification = {data: {title: error.message, class: toastClass}, autohide: true}
                this.sharedService.showNotification(notification);
            })
        ),
        {dispatch: false}
    )

    /**
     * check if the user is logged in
     */
     getUserStatus$ = createEffect( () => 
        this.actions$.pipe(
            ofType(getAuthUser),
            mergeMap(() => 
                this.authService.isLoggedIn()
                .pipe(
                    map((user) => (getAuthUserSuccess({user}))),
                    catchError((error) => of(getAuthUserFailure(error)))
                )
            )
        )
    )
    /**
     * Logout Request Effect
     */
    logOutRequest$ = createEffect( () => 
        this.actions$.pipe(
            ofType(logoutUser),
            switchMap((action) => this.authService.logout(action.decorator)
                .pipe(
                    map((status: boolean) => logoutUserSuccess({status})),
                    catchError((error) => of(logoutUserFailure({error})))
                )
            )
        )
    )
}