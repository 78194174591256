<main class="mt-2 p-4">
    <div class="row">
        <div class="col-12">
            <nav aria-label="breadcrumb" class="d-flex justify-content-between align-items-center cart-nav">
                <ol class="breadcrumb mt-3 mb-0">
                    <li class="breadcrumb-item"><a [href]="'./cart/' + cart.cartId">Cart</a></li>
                    <li class="breadcrumb-item active" aria-current="page">{{cart.cartName}}</li>
                </ol>
                <ol class="breadcrumb mt-3 mb-0">
                    <li class="breadcrumb-item text-danger">
                        <div type="button" (click)="deleteCart()">
                            Delete Cart
                        </div>
                    </li>
                </ol>
            </nav>
        </div>
    </div>
    
    

    <div class="row">
        <div class="card col-lg-8 mb-5 mb-lg-0">
            <ng-container *ngIf="cart?.designs?.length > 0; else emptyCart">
                <ng-container *ngFor="let design of cart?.designs">
                    <div class="d-md-flex justify-content-start pb-4 mb-4">
                        <div>
                            <div class="carousel-inner rounded-5 shadow-4-strong">
                                <mdb-carousel [interval]="false" [indicators]="true" [dark]="true"
                                    class="fixed-image-dimensions">
                                    <ng-container *ngFor="let position of design?.positions">
                                        <ng-container *ngIf="position?.mockupImage">
                                            <mdb-carousel-item>
                                                <img [src]="position.mockupImage" class="img-fluid p-4" [alt]="position.position + ' mockup of design: ' + design.designName" />
                                            </mdb-carousel-item>
                                        </ng-container>
                                    </ng-container>
                                </mdb-carousel>
                            </div>
                        </div>
                        <div class="w-100 ps-md-4">
                            <div class="d-flex justify-content-between">
                                <div class="p-3">
                                    <p class="h6 pb-1"><a href="#!" class="text-reset">{{design?.products?.brand}} {{
                                            design?.products?.style }}
                                            {{ design?.products?.title }}</a></p>
                                    <p class="pt-1 mb-1">Design: <span>{{design.designName}}</span></p>
                                    <p class="pt-1 mb-3">Color: <span>
                                            {{design?.products?.variants[0]?.color}}
                                        </span></p>
                                    <div class="d-flex flex-wrap mb-3">
                                        <span *ngIf="design?.products && design?.products.variants"
                                            class="cart-chip me-2"
                                            (click)="openVariantModal(cart, design)">
                                            Qty: {{getProductSizeQtys(design?.products?.variants)}}
                                            <i class="fas fa-pen fa-sm ms-2"></i>
                                        </span>
                                    </div>
                                    <span class="fw-bolder">
                                        {{getProductPrice(design?.products?.variants) | currency}}
                                    </span> total

                                    <div class="d-flex flex-wrap mt-2" style="align-items: flex-end;">
                                        <span class="me-2">
                                            <button class="cart-actions"
                                                (click)="editDesign(design)"><u>Edit</u>
                                            </button>
                                        </span>
                                        <span class="">
                                            <button class="cart-actions"
                                                (click)="deleteDesign(cart, design, design?.products)"><u>Delete</u>
                                            </button>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </ng-container>

        </div>

        <div class="col-lg-4">
            <div class="card">
                <div class="card-body">
                    <div class="d-flex justify-content-between pb-3 mb-3">
                        <h6 class="mb-0">Subtotal</h6>
                        <h6 class="mb-0">{{ calculateGrandSubtotal() | currency }}</h6>
                    </div>
                    <div class="d-flex justify-content-between border-bottom pb-3 mb-3">
                        <h6 class="mb-0">Tax</h6>
                        <h6 class="mb-0">{{tax | currency}}</h6>
                    </div>
                    <div class="d-flex justify-content-between mb-3">
                        <p class="mb-2"><span class="h6">Total</span></p>
                        <h6>{{calculateGrandTotal() | currency}}</h6>
                    </div>
                    <p class="small mb-4">We use secure payment gateways to protect your financial information. Your
                        payment details are encrypted and stored securely. Applicable taxes and duties may be added to
                        your order based on your location. You will receive an order confirmation email with details of
                        your purchase. We advise you to keep this for your records.</p>
                    <ng-container *ngIf="cartId">

                        <button type="button" class="btn btn-outline-primary btn-block"
                            style="color: #2B374D; border-color: #2B374D;" [routerLink]="'/catalog'">Continue
                            Shopping</button>

                        <button type="button" class="btn btn-primary btn-block"
                            style="background: var(--primary-background, #2B374D);"
                            [routerLink]="'/cart/' + cartId + '/checkout'">Proceed to Checkout</button>


                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</main>

<ng-container *ngIf="screenWidth > 991; else mobileView">
    <section class="pt-0 ps-4 pe-4 pb-4">
        <div class="row mt-4">
            <ng-container *ngIf="section0">
                <h3 class="base-font fs-4">{{productSectionName0}}</h3>
                <div class="d-flex justify-content-start">
                    <div class="clearfix d-flex align-items-center pe-2">
                        <i class="fas fa-chevron-left float-start" (click)="scrollLeft(overflowDesignProducts)"
                            *ngIf="section0.length > 6"></i>
                    </div>
                    <div class="custom-scroll-main" #overflowDesignProducts>
                        <ng-container>
                            <div class="col-sm-{{12 / (section0?.length || 6)}}" *ngFor="let product of section0">
                                <div class="bg-image hover-overlay ripple rounded px-1" data-mdb-ripple-color="light">
                                    <a href="{{product.url}}">
                                        <img
                                        loading="lazy"
                                        src="{{product.productImage.fields.file.url}}" class="design-tool-images"
                                            [alt]="'Image of ' + product.brand + ' ' + product.styleId + ' ' +  product.name" />
                                        <div class="mask" style="background-color: rgba(251, 251, 251, 0.2);"></div>
                                    </a>
                                    <div class="">
                                        <span class="fw-bold">
                                            {{ product.brand }}
                                        </span>
                                        <span>
                                            {{ product.styleId }} {{ product.name }}
                                        </span>
                                    </div>
                                    <div>
                                        <span class="fw-lighter">
                                            in {{ product.category }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="section0.length > 6">
                            <div class="col-sm-2" *ngFor="let product of section0">
                                <div class="bg-image hover-overlay ripple rounded px-1" data-mdb-ripple-color="light">
                                    <a href="{{product.url}}">
                                        <img
                                        loading="lazy"
                                        src="{{product.productImage.fields.file.url}}" class="img-fluid"
                                        [alt]="'Image of ' + product.brand + ' ' + product.styleId + ' ' +  product.name" />
                                        <div class="mask" style="background-color: rgba(251, 251, 251, 0.2);"></div>
                                    </a>
                                    <div class="">
                                        <span class="fw-bold">
                                            {{ product.brand }}
                                        </span>
                                        <span>
                                            {{ product.styleId }} {{ product.name }}
                                        </span>
                                    </div>
                                    <div>
                                        <span class="fw-lighter">
                                            in {{ product.category }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                    <div class="clearfix d-flex align-items-center pe-2">
                        <i class="fas fa-chevron-right float-end" (click)="scrollRight(overflowDesignProducts)"
                            *ngIf="section0.length > 6"></i>
                    </div>
                </div>
            </ng-container>
        </div>
        <div class="row mt-4">
            <ng-container *ngIf="section1">
                <h3 class="base-font fs-4">{{productSectionName1}}</h3>
                <div class="d-flex justify-content-start">
                    <div class="clearfix d-flex align-items-center pe-2">
                        <i class="fas fa-chevron-left float-start" (click)="scrollLeft(overflowSection1)"
                            *ngIf="section1.length > 6"></i>
                    </div>
                    <div class="custom-scroll-main" #overflowSection1>
                        <div class="col-sm-2" *ngFor="let product of section1">
                            <div class="bg-image hover-overlay ripple rounded px-1" data-mdb-ripple-color="light">
                                <a href="{{product.url}}">
                                    <img loading="lazy" src="{{product.productImage.fields.file.url}}" class="img-fluid"
                                    [alt]="'Image of ' + product.brand + ' ' + product.styleId + ' ' +  product.name" />
                                    <div class="mask" style="background-color: rgba(251, 251, 251, 0.2);"></div>
                                </a>
                                <div class="">
                                    <span class="fw-bold">
                                        {{ product.brand }}
                                    </span>
                                    <span>
                                        {{ product.styleId }} {{ product.name }}
                                    </span>
                                </div>
                                <div>
                                    <span class="fw-lighter">
                                        in {{ product.category }}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="clearfix d-flex align-items-center pe-2">
                        <i class="fas fa-chevron-right float-end" (click)="scrollRight(overflowSection1)"
                            *ngIf="section1.length > 6"></i>
                    </div>
                </div>
            </ng-container>
        </div>
        <div class="row mt-4">
            <ng-container *ngIf="section2">
                <h3 class="base-font fs-4">{{productSectionName2}}</h3>
                <div class="d-flex justify-content-start">
                    <div class="clearfix d-flex align-items-center pe-2">
                        <i class="fas fa-chevron-left float-start" (click)="scrollLeft(overflowSection2)"
                            *ngIf="section2.length > 6"></i>
                    </div>
                    <div class="custom-scroll-main" #overflowSection2>
                        <div class="col-sm-2" *ngFor="let product of section2">
                            <div class="bg-image hover-overlay ripple rounded px-1" data-mdb-ripple-color="light">
                                <a href="{{product.url}}">
                                    <img loading="lazy" src="{{product.productImage.fields.file.url}}" class="img-fluid"
                                    [alt]="'Image of ' + product.brand + ' ' + product.styleId + ' ' +  product.name" />
                                    <div class="mask" style="background-color: rgba(251, 251, 251, 0.2);"></div>
                                </a>
                                <div class="">
                                    <span class="fw-bold">
                                        {{ product.brand }}
                                    </span>
                                    <span>
                                        {{ product.styleId }} {{ product.name }}
                                    </span>
                                </div>
                                <div>
                                    <span class="fw-lighter">
                                        in {{ product.category }}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="clearfix d-flex align-items-center pe-2">
                        <i class="fas fa-chevron-right float-end" (click)="scrollRight(overflowSection2)"
                            *ngIf="section2.length > 6"></i>
                    </div>
                </div>
            </ng-container>
        </div>
        <div class="row mt-4">
            <ng-container *ngIf="section3">
                <h3 class="base-font fs-4">{{productSectionName3}}</h3>
                <div class="d-flex justify-content-start">
                    <div class="clearfix d-flex align-items-center pe-2">
                        <i class="fas fa-chevron-left float-start" (click)="scrollLeft(overflowSection3)"
                            *ngIf="section3.length > 6"></i>
                    </div>
                    <div class="custom-scroll-main" #overflowSection3>
                        <div class="col-sm-2" *ngFor="let product of section3">
                            <div class="bg-image hover-overlay ripple rounded px-1" data-mdb-ripple-color="light">
                                <a href="{{product.url}}">
                                    <img loading="lazy" src="{{product.productImage.fields.file.url}}" class="img-fluid"
                                    [alt]="'Image of ' + product.brand + ' ' + product.styleId + ' ' +  product.name" />
                                    <div class="mask" style="background-color: rgba(251, 251, 251, 0.2);"></div>
                                </a>
                                <div class="">
                                    <span class="fw-bold">
                                        {{ product.brand }}
                                    </span>
                                    <span>
                                        {{ product.styleId }} {{ product.name }}
                                    </span>
                                </div>
                                <div>
                                    <span class="fw-lighter">
                                        in {{ product.category }}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="clearfix d-flex align-items-center pe-2">
                        <i class="fas fa-chevron-right float-end" (click)="scrollRight(overflowSection3)"
                            *ngIf="section3.length > 6"></i>
                    </div>
                </div>
            </ng-container>
        </div>
    </section>
</ng-container>
<cs-footer></cs-footer>


<ng-template #emptyCart>
    <div class="d-flex flex-column align-items-center justify-content-center h-100">
        <div class="text-center mb-3">
          <span>Your cart is empty</span>
        </div>
        <div class="row">
          <div class="col-12">
            <button type="button" class="btn btn-primary btn-block"
                    style="background: var(--primary-background, #2B374D);"
                    [routerLink]="'/catalog'">Browse our Catalog</button>
          </div>
        </div>
      </div>

</ng-template>

<ng-template #mobileView>
    <section class="pt-0 ps-4 pe-4 pb-4">
        <div class="row mt-4">
            <ng-container *ngIf="section0">
                <h3 class="base-font fs-4">{{productSectionName0}}</h3>
                        <div class="custom-scroll">
                            <div *ngFor="let product of section0" class="col-12">
                                <div class="bg-image hover-overlay ripple rounded px-1" data-mdb-ripple-color="light">
                                    <a href="{{product.url}}">
                                        <img
                                        loading="lazy"
                                        src="{{product.productImage.fields.file.url}}" class="img-fluid"
                                            [alt]="'Image of ' + product.brand + ' ' + product.styleId + ' ' +  product.name" />
                                        <div class="mask" style="background-color: rgba(251, 251, 251, 0.2);"></div>
                                    </a>
                                </div>
                                <div class="">
                                    <span class="fw-bold">
                                        {{ product.brand }}
                                    </span>
                                    <span class="text-wrap">
                                        {{ product.styleId }} {{ product.name }}
                                    </span>
                                </div>
                                <div>
                                    <span class="fw-lighter">
                                        in {{ product.category }}
                                    </span>
                                </div>
                            </div>
                        </div>
            </ng-container>
        </div>

        <div class="row mt-4">
            <ng-container *ngIf="section1">
                <h3 class="base-font fs-4">{{productSectionName1}}</h3>
                        <div class="custom-scroll">
                            <div *ngFor="let product of section1" class="col-12">
                                <div class="bg-image hover-overlay ripple rounded px-1" data-mdb-ripple-color="light">
                                    <a href="{{product.url}}">
                                        <img
                                        loading="lazy"
                                        src="{{product.productImage.fields.file.url}}" class="img-fluid"
                                            [alt]="'Image of ' + product.brand + ' ' + product.styleId + ' ' +  product.name" />
                                        <div class="mask" style="background-color: rgba(251, 251, 251, 0.2);"></div>
                                    </a>
                                </div>
                                <div class="">
                                    <span class="fw-bold">
                                        {{ product.brand }}
                                    </span>
                                    <span class="text-wrap">
                                        {{ product.styleId }} {{ product.name }}
                                    </span>
                                </div>
                                <div>
                                    <span class="fw-lighter">
                                        in {{ product.category }}
                                    </span>
                                </div>
                            </div>
                        </div>
            </ng-container>
        </div>
        <div class="row mt-4">
            <ng-container *ngIf="section2">
                <h3 class="base-font fs-4">{{productSectionName2}}</h3>
                        <div class="custom-scroll">
                            <div *ngFor="let product of section2" class="col-12">
                                <div class="bg-image hover-overlay ripple rounded px-1" data-mdb-ripple-color="light">
                                    <a href="{{product.url}}">
                                        <img
                                        loading="lazy"
                                        src="{{product.productImage.fields.file.url}}" class="img-fluid"
                                            [alt]="'Image of ' + product.brand + ' ' + product.styleId + ' ' +  product.name" />
                                        <div class="mask" style="background-color: rgba(251, 251, 251, 0.2);"></div>
                                    </a>
                                </div>
                                <div class="">
                                    <span class="fw-bold">
                                        {{ product.brand }}
                                    </span>
                                    <span class="text-wrap">
                                        {{ product.styleId }} {{ product.name }}
                                    </span>
                                </div>
                                <div>
                                    <span class="fw-lighter">
                                        in {{ product.category }}
                                    </span>
                                </div>
                            </div>
                        </div>
            </ng-container>
        </div>
        <div class="row mt-4">
            <ng-container *ngIf="section3">
                <h3 class="base-font fs-4">{{productSectionName3}}</h3>
                        <div class="custom-scroll">
                            <div *ngFor="let product of section3" class="col-12">
                                <div class="bg-image hover-overlay ripple rounded px-1" data-mdb-ripple-color="light">
                                    <a href="{{product.url}}">
                                        <img
                                        loading="lazy"
                                        src="{{product.productImage.fields.file.url}}" class="img-fluid"
                                            [alt]="'Image of ' + product.brand + ' ' + product.styleId + ' ' +  product.name" />
                                        <div class="mask" style="background-color: rgba(251, 251, 251, 0.2);"></div>
                                    </a>
                                </div>
                                <div class="">
                                    <span class="fw-bold">
                                        {{ product.brand }}
                                    </span>
                                    <span class="text-wrap">
                                        {{ product.styleId }} {{ product.name }}
                                    </span>
                                </div>
                                <div>
                                    <span class="fw-lighter">
                                        in {{ product.category }}
                                    </span>
                                </div>
                            </div>
                        </div>
            </ng-container>
        </div>
    </section>
</ng-template>
