
  <ng-container *ngIf="(!!(errorService.errorState$ | async) && (errorService.showError$ | async) === true) else content">
    <app-error></app-error>
  </ng-container>
  <ng-template #content>
    <router-outlet></router-outlet>
    <app-http-loader></app-http-loader>
  </ng-template>
  <mdb-loading *ngIf="(globalLoaderService.loaderState | async) as progress" [show]="true" [fullscreen]="true">
    <div class="loading-spinner">
        <div class="spinner-border loading-icon" style="color: #FFF;" role="status"></div>
        <span class="loading-text" style="color: #FFF;">{{globalLoaderService.message ?? 'Please wait. This may take a while...'}}</span>
        <span class="loading-text" style="color: #FFF;" *ngIf="progress?.length > 0">{{progress}}</span>
    </div>
  </mdb-loading>
