import { Injectable } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class MetaService {

  constructor(
      private title: Title, 
      private meta: Meta,
      ) { }


  //
  //** this service needs to be called in ngOnInit lifecycle in order for SEO to work properly **/
  //

  updateMetaTags(title: string, description: string, og_description: string ,image: string) {
    
    // Browser metadata
    this.title.setTitle(`${title} - Culture Studio Platform`); //PARAM 1 - title on google and also title that will show up on browser tab
    this.meta.updateTag({ name: 'description', content: description }); // PARAM 2 - description that will show up on google search

    // Open Graph metadata that is used for Social Media sharing
    this.meta.updateTag({ property: 'og:title', content: `${title} - Culture Studio Platform` }); // PARAM 1 - title for open graph
    this.meta.updateTag({ property: 'og:description', content: og_description }); // PARAM 3 - description for open graph
    this.meta.updateTag({ property: 'og:image', content: image }); // PARAM 4 - image for open graph
    this.meta.updateTag({ property: 'og:url', content: window.location.href}); // this is handled within the service - grabs current URL
  }

  extractValuesAsString(paramArray: any[]): string {
    const valuesArray = paramArray.reduce((acc, obj) => {
      // Extract values from each object and concatenate them to the accumulator array
      return acc.concat(Object.values(obj));
    }, []);
  
    // Convert values array to a string with commas separating each value
    return valuesArray.join(', ');
  }

  extractSearchParamsAsString(params: { [key: string]: any }): string {
    const valuesArray = [];
  
    for (const key in params) {
      if (Array.isArray(params[key])) {
        // If the value is an array, concatenate it to the valuesArray
        valuesArray.push(...params[key]);
      } else {
        // If the value is not an array, push it directly to the valuesArray
        valuesArray.push(params[key]);
      }
    }
  
    // Convert values array to lowercase and join with commas
    return valuesArray.map(value => String(value).toLowerCase()).join(', ');
  }
}
