import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';


if (environment.production) {
  enableProdMode();

  // Initialize Datadog RUM in production mode only
  import('@datadog/browser-rum').then(({ datadogRum }) => {
    datadogRum.init({
        applicationId: '94d2509f-28f7-4ba1-9884-b49171cf036b',
        clientToken: 'pub2b3e219db01ee9926fd6dbbf3251d1ea',
        site: 'datadoghq.com',
        service: 'culture-studio-client-platform',
        env: 'prod',
        version: '4.0.3',
        sessionSampleRate: 100,
        sessionReplaySampleRate: 100,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'allow'
    });
  });

  import('@datadog/browser-logs').then(({ datadogLogs }) => {
    datadogLogs.init({
      clientToken: 'pub2b3e219db01ee9926fd6dbbf3251d1ea',
      site: 'datadoghq.com',
      service: 'culture-studio-client-platform',
      env: 'prod',
      version: '4.0.3',
      forwardErrorsToLogs: true,
      sessionSampleRate: 100,
    });
  });
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
