import {Injectable} from "@angular/core";
import { environment } from 'environments/environment';
import {HttpClient} from "@angular/common/http";

@Injectable({ providedIn: 'root' })
export class ApiService{
  protected api: string;
  protected apiRoot: string;
  protected apiV2: string;

  constructor(protected http: HttpClient){
    this.api = environment.apiUrl;
    this.apiV2 = environment.apiUrl2;
    this.apiRoot = environment.apiRoot;
    this.http = http;
  }

  public get(uri: string, params?: Object){
    let url = this.buildParamUrl(uri, params);
    return this.http.get(url);
  }

  public getV2(uri: string, params?: Object){
    let url = this.buildParamUrlv2(uri, params);
    return this.http.get(url);
  }

  public postV2(uri: string, params?: Object, config?: any){
    let url = this.buildUrlv2(uri);
    return this.http.post(url, params, config);
  }

  public getR(uri: string, params?: Object){
    let url = this.buildParamUrlRoot(uri, params);
    return this.http.get(url);
  }

  public post(uri: string, data: any, params?: Object){
    let url = this.buildParamUrl(uri, params);
    return this.http.post(url, data);
  }

  public postUpload(uri: string, data: any, params?: Object){
    let url = this.buildParamUrlRoot(uri, params);
    return this.http.post(url, data, {headers: {'Content-Type': undefined}});
  }

  public put(uri: string, data: any, params?: Object){
    let url = this.buildParamUrl(uri, params);
  }

  public patch(uri: string, data: any, params?: Object){
    let url = this.buildParamUrl(uri, params);
  }

  public del(uri: string, data: any, params?: Object){
    let url = this.buildParamUrl(uri, params);
  }

  protected buildParamUrl(uri: string, params?: Object):string{
    if(params && Object.keys(params).length){
      let paramStr = Object.keys(params).map(key => key + '=' + params[key]).join('&');

      return this.api+uri+'?'+paramStr;
    }
    return this.api+uri;
  }

  protected buildParamUrlv2(uri: string, params?: Object):string{
    if(params && Object.keys(params).length){
      let paramStr = Object.keys(params).map(key => key + '=' + params[key]).join('&');

      return this.apiV2+uri+'?'+paramStr;
    }
    return this.apiV2+uri;
  }

  protected buildUrlv2(uri: string):string{
    return this.apiV2+uri;
  
  }

  protected buildParamUrlRoot(uri: string, params?: Object):string{
    if(params && Object.keys(params).length){
      let paramStr = Object.keys(params).map(key => key + '=' + params[key]).join('&');
      return this.apiRoot+uri+'?'+paramStr;
    }
    return this.apiRoot+uri;
  }

  getApiv2(uri: string){
    let url = this.apiV2+uri;
    return this.http.get(url);
  }


}