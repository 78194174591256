import { HttpLink } from "apollo-angular/http";
import { setContext } from '@apollo/client/link/context';
import { environment } from "../environments/environment";
import { ApolloLink, InMemoryCache, makeVar } from "@apollo/client/core";

export const products = makeVar([]);


export function createApollo(httpLink: HttpLink){
    const uri = environment.apiAWS
    const basic = setContext((operation, context) => ({
        headers: {
            'x-api-key': environment.apiAWSKey
        }
    }))
    const link = ApolloLink.from([basic, httpLink.create({uri})]);
    const cache = new InMemoryCache({
        typePolicies: {
            Query: {
                fields: {
                    items: {
                        read() {
                            return products()
                        }
                    }
                }
            }
        }
    });

    return {
        link, cache
    }
}