import { Type, Entity, EntityBuilder } from '@decahedron/entity';

export class User extends Entity{
  public id: number = null;
  public email: string = null;
  public token: string = null;
  public firstName: string = null;
  public lastName: string = null;
  public lastLogin: string = null;
  public roleId: number = null;
  public emailConfirmed: string = null;
  public avatarImg: string = null;
  public avatarTwoChar: string = null;
  public isAffiliateUser: string = null;
  public createdDate: string = null;
}

export interface IUser {
  id: number;
  email: string;
  token: string;
  firstName: string;
  lastName: string;
  lastLogin: string;
  roleId: number;
  emailConfirmed: string;
  avatarImg: string;
  avatarTwoChar: string;
  isAffiliateUser: string;
  createdDate: string;
}

export class IUserGuest extends Entity{
  public name: string = null;
  public email: string = null;  
}