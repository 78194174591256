import { Type, Entity, EntityBuilder } from '@decahedron/entity';

export class Decorator extends Entity{
  public id: number = null;
  public name: string = null;
  public address: string = null;
  public city: string = null;
  public state: string = null;
  public postalCode: string = null;
  public phone: string = null;
  public email: string = null;
  public avatarImg: string = null;
  public avatarTwoChar: string = null;
}

export interface IDecorator {
  id: number,
  name: string,
  address: string,
  city: string,
  state: string,
  postalCode: string,
  phone: string,
  email: string,
  avatarImg: string,
  avatarTwoChar: string
}

export interface IDecoratorResponse {
  data: [IDecorator],
  message: string,
  success: boolean
}