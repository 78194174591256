import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, of, switchMap, tap } from "rxjs";
import { getOrderInfo,getOrderInfoHeaders, getOrderInfoSuccess, getOrderInfoFailure, getAffiliateOrderInfo, getAffiliateOrderInfoFailure, getAffiliateOrderInfoSuccess, getOrderDetail, getOrderDetailSuccess, getOrderDetailFailure, createOrderEstimate, createOrderEstimateSuccess, createOrderEstimateFailure, uploadFilesNewOrderEstimate, uploadFilesNewOrderEstimateSuccess, uploadFilesNewOrderEstimateFailure, getOrderInfoHeadersSuccess, getOrderInfoHeadersFailure, getOrderInfoAffiliateHeaders, getOrderInfoAffiliateHeadersSuccess, getOrderInfoAffiliateHeadersFailure, createArtApprovalFeedback, createArtApprovalFeedbackSuccess, createArtApprovalFeedbackFailure, getImagesForDesign, getImagesForDesignSuccess, getImagesForDesignFailure, getOrderDetailV2, getOrderDetailV2Success, getOrderDetailV2Failure, submitArtApprovalApproval, submitArtApprovalApprovalSuccess, submitArtApprovalApprovalFailure } from "./order.actions";
import { IOrder, IOrderCounts, IOrderDetail } from "../../models/order";
import { OrdersService } from "src/app/features/orders/orders.service";
import { SharedService } from "src/app/shared/shared.service";
import { Router } from "@angular/router";

@Injectable()
export class OrderEffects {
    constructor(
        private actions$: Actions,
        private ordersService: OrdersService,
        private sharedService: SharedService,
        private router: Router
    ){}
    getOrderInfo$ = createEffect( () =>
        this.actions$.pipe(
            ofType(getOrderInfo),
            switchMap((action) => this.ordersService.getAllOrders(action.clientId, action.status)
                .pipe(
                    map((orderResponse: [IOrder]) => 
                    getOrderInfoSuccess({orderResponse})
                    ),
                    catchError((error) => of(getOrderInfoFailure({error})))
                )
            )
        )
    )

    getOrderHeaders = createEffect(() => 
        this.actions$.pipe(
            ofType(getOrderInfoHeaders),
            switchMap((action) => this.ordersService.getAllOrderHeaders(action.clientId)
                .pipe(
                    map((orderResponse: IOrderCounts) => getOrderInfoHeadersSuccess({orderResponse})),
                    catchError((error) => of(getOrderInfoHeadersFailure({error})))
                ))
    ))
    getOrderAffiliateHeaders = createEffect(() => 
        this.actions$.pipe(
            ofType(getOrderInfoAffiliateHeaders),
            switchMap((action) => this.ordersService.getAllOrderAffiliateHeaders(action.userId, action.decoratorId)
                .pipe(
                    map((orderResponse: IOrderCounts) => getOrderInfoAffiliateHeadersSuccess({orderResponse})),
                    catchError((error) => of(getOrderInfoAffiliateHeadersFailure({error})))
                ))
    ))

    getAffiliateOrderInfo$ = createEffect( () =>
        this.actions$.pipe(
            ofType(getAffiliateOrderInfo),
            switchMap((action) => this.ordersService.getAllAffiliateOrders(action.userId, action.decoratorId, action.status)
                .pipe(
                    map((affiliateResponse: [IOrder]) => 
                    getAffiliateOrderInfoSuccess({affiliateResponse})
                    ),
                    catchError((error) => of(getAffiliateOrderInfoFailure({error})))
                )
            )
        )
    )

    getOrderDetail$ = createEffect( () =>
        this.actions$.pipe(
            ofType(getOrderDetail),
            switchMap((action) => this.ordersService.getOrderDetail(action.orderId)
                .pipe(
                    map((orderDetailResponse: [IOrderDetail]) => 
                    getOrderDetailSuccess({orderDetailResponse})
                    ),
                    catchError((error) => of(getOrderDetailFailure({error})))
                )
            )
        )
    )
    getOrderDetailV2$ = createEffect( () =>
        this.actions$.pipe(
            ofType(getOrderDetailV2),
            switchMap((action) => this.ordersService.getOrderDetailV2(action.displayNumber, action.companyId)
                .pipe(
                    map((response: any) => 
                    getOrderDetailV2Success({response})
                    ),
                    catchError((error) => of(getOrderDetailV2Failure({error})))
                )
            )
        )
    )

    createorderEstimate$ = createEffect( () => 
        this.actions$.pipe(
            ofType(createOrderEstimate),
            switchMap((action) => this.ordersService.createOrderEstimate(action.data, action.files)
                .pipe(
                    map((dataResponse: any) => createOrderEstimateSuccess(dataResponse)),
                    catchError((error) => of(createOrderEstimateFailure({error})))
                ))
        )
    )
    
    createArtApprovalFeedback$ = createEffect( () => 
        this.actions$.pipe(
            ofType(createArtApprovalFeedback),
            switchMap((action) => this.ordersService.createFeedback(action.data)
                .pipe(
                    switchMap(res => of(
                        createArtApprovalFeedbackSuccess(res),
                        getOrderDetailV2({ displayNumber: action.data.displayNumber, companyId: action.data.companyId })
                    )),
                    catchError((error) => of(createArtApprovalFeedbackFailure({error})))
                ))
        )
    )

    submitArtApprovalApproval$ = createEffect( () => 
        this.actions$.pipe(
            ofType(submitArtApprovalApproval),
            switchMap((action) => this.ordersService.artApprovalEmailResponse(action.data.type, action.data.order_id, action.data.company_id, action.data.design_id, action.data.payload)
                .pipe(
                    switchMap((dataResponse) => of( submitArtApprovalApprovalSuccess(dataResponse) )),
                    tap(() => {
                        const { order_id, design_id, position_id } = action.data;
                        this.router.navigate([`/orders/${order_id}/designs/${design_id}/positions/${position_id}/art-approval/confirmation`]);
                    }),
                    catchError((error) => of(submitArtApprovalApprovalFailure({error})))
                ))
        )
    )

    getImagesForDesign$ = createEffect( () =>
        this.actions$.pipe(
            ofType(getImagesForDesign),
            switchMap((action) => this.ordersService.getImagesForDesign(action.data)
                .pipe(
                    map((response: any) => getImagesForDesignSuccess({response})),
                    catchError((error) => of(getImagesForDesignFailure({error})))
                )
            )
        )
    )

    uploadFilesOrderEstimate$ = createEffect( () => 
        this.actions$.pipe(
            ofType(uploadFilesNewOrderEstimate),
            switchMap((action) => this.ordersService.uploadFilesOrderEstimate(action.fdata, action.orderId)
            .pipe(
                map((dataResponse: any) => uploadFilesNewOrderEstimateSuccess(dataResponse)),
                catchError((error) => of(uploadFilesNewOrderEstimateFailure({error})))
            ))
        )

    )

    createOrderEstimateSuccessNotification$ = createEffect(() => 
        this.actions$.pipe(
            ofType(createOrderEstimateSuccess),
            map(() => {
                let message = "Estimate has been submitted! An Account Manager will reach out once Estimate is ready for review! "
                let toastClass = 'bg-success';        
                let notification = {data: {title: message, class: toastClass}, autohide: true}
                this.sharedService.showNotification(notification);
                this.router.navigate(['/orders']);               
            })
        ),
        { dispatch: false }
    )
        
    submitFeedbackSuccessNotification$ = createEffect(() =>
        this.actions$.pipe(
            ofType(createArtApprovalFeedbackSuccess),
            map(() => {
                let message = "Feedback has been submitted! "
                let toastClass = 'bg-success';
                let notification = { data: { title: message, class: toastClass }, autohide: true }
                this.sharedService.showNotification(notification);
            })
        ),
        { dispatch: false }
    )
    submitFeedbackFailureNotification$ = createEffect(() =>
        this.actions$.pipe(
            ofType(createArtApprovalFeedbackFailure),
            map((action) => {
                let error = action.error['error']
                let toastClass = 'bg-danger';
                let notification = { data: { title: error.text, class: toastClass }, autohide: true }
                this.sharedService.showNotification(notification);
            })
        ),
        { dispatch: false }
    )

    submitArtApprovalApprovalSuccessNotification$ = createEffect(() =>
        this.actions$.pipe(
            ofType(submitArtApprovalApprovalSuccess),
            map(() => {
                let message = "Art Approval has been submitted! "
                let toastClass = 'bg-success';
                let notification = { data: { title: message, class: toastClass }, autohide: true }
                this.sharedService.showNotification(notification);
            })
        ),
        { dispatch: false }
    )
    submitArtApprovalApprovalFailureNotification$ = createEffect(() =>
        this.actions$.pipe(
            ofType(submitArtApprovalApprovalFailure),
            map((action) => {
                let error = action.error['error']
                let toastClass = 'bg-danger';
                let notification = { data: { title: error.text, class: toastClass }, autohide: true }
                this.sharedService.showNotification(notification);
            })
        ),
        { dispatch: false }
    )

} 