import { Injectable } from '@angular/core';
import {IUser, User, IUserGuest} from "../../models/user";
import {Client, IClient} from "../../models/client";
import {Decorator, IDecorator} from "../../models/decorator";
import { EntityBuilder } from '@decahedron/entity';
import { Observable, of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SessionService{

  protected userKey: string;
  protected rememberTokenKey: string;
  protected authTokenKey: string;
  protected activeDecoratorKey: string;
  protected activeClientKey: string;
  protected authEmailKey: string;
  //protected protected_keys: string[];
  protected driver: any;
  protected activeMemory: Map<string,any>;

  constructor() {
    this.userKey = '_user';
    this.rememberTokenKey = '_remember_token';
    this.authTokenKey = '_auth_token';
    this.authEmailKey = '_auth_email';
    this.activeDecoratorKey = '_active_decorator';
    this.activeClientKey = '_active_client';
    /*this.protected_keys = [
        this.userKey, this.rememberTokenKey, this.authTokenKey, this.authEmailKey
    ];*/

    // TODO: better remember token handler
    //this.driver = (this.getObject(this.rememberTokenKey))? localStorage : sessionStorage;
    this.driver = localStorage;

    this.activeMemory = new Map();

    //FLUSH ALL
    //this.driver.clear();

    //this.flushUser();
    //this.spoofPreAuth();

  }

  public has(key){
    return this.activeMemory.has(key) || this.driver.getItem(key) !== null;
  }

  public setItem(key:string, value: string): string {
    this.activeMemory.set(key, value);
    this.driver.setItem(key, value);
    return this.activeMemory.get(key);
  }

  public getItem(key:string): string {
    if(this.activeMemory.has(key)){
      if(this.driver.getItem(key) === null){
        this.driver.setItem(key, this.activeMemory.get(key));
      }
    }
    else if(this.driver.getItem(key) !== null){
      this.activeMemory.set(key, this.driver.getItem(key));
    }

    return this.activeMemory.get(key);
  }

  public removeItem(key:string): void {
    if(this.activeMemory.has(key)){
      this.activeMemory.delete(key);
    }
    this.driver.removeItem(key);
  }

  public setObject(key:string, value: Object): Object {
    this.activeMemory.set(key, value);
    this.driver.setItem(key, JSON.stringify(value));
    return this.activeMemory.get(key);
  }

  public getObject(key:string): Object {
    if(this.activeMemory.has(key)){
      if(this.driver.getItem(key) === null){
        this.driver.setItem(key, JSON.stringify(this.activeMemory.get(key)));
      }
    }
    else if(this.driver.getItem(key) !== null){
      this.activeMemory.set(key, JSON.parse(this.driver.getItem(key)));
    }

    return this.activeMemory.get(key);
  }

  public removeObject(key:string): void {
    this.activeMemory.delete(key);
    this.driver.removeItem(key);
  }

  public getUser(): User{

    if(!this.has(this.userKey)){
      return null;
    }
    let user = EntityBuilder.buildOne<User>(User, this.getObject(this.userKey));
    return user;
  }

  public getUserGuest(key: string): IUserGuest{
    if(!this.has(key)){
      return null;
    }
    let user = EntityBuilder.buildOne<IUserGuest>(IUserGuest, this.getObject(key));

    return user;
  }

  public setUser(user_obj:IUser): IUser{
    this.setObject(this.userKey, user_obj);
    this.setItem(this.authEmailKey, user_obj.email);
    this.setItem(this.authTokenKey, user_obj.token);
    let user = EntityBuilder.buildOne<User>(User, this.getObject(this.userKey));
    return user;
  }

  public setActiveDecorator(decorator): Observable<Decorator>{
    this.setObject(this.activeDecoratorKey, decorator);
    return of(decorator);
  }

  public getActiveDecorator(): Decorator{
    if(!this.has(this.activeDecoratorKey)){
      return null;
    }
    return EntityBuilder.buildOne<Decorator>(Decorator, this.getObject(this.activeDecoratorKey));
  }

  public getUserDecorator(): Observable<IDecorator>{
    let decorator: IDecorator = JSON.parse(localStorage.getItem(this.activeDecoratorKey))
    return of(decorator);
  }

  public getUserClient(): Observable<IClient>{
    let client: IClient = JSON.parse(localStorage.getItem(this.activeClientKey))
    return of(client);
  }

  public setActiveClient(client): Observable<IClient>{
    this.setObject(this.activeClientKey, client);
    return of(client);
  }

  public getActiveClient(): Client{
    if(!this.has(this.activeClientKey)){
      return null;
    }
    return EntityBuilder.buildOne<Client>(Client, this.getObject(this.activeClientKey));
  }

  public getPreAuthToken(): string{
    let token = this.getItem(this.authTokenKey);
    //let remember_token = this.getObject(this.rememberTokenKey);
    if(!token){
      return null;
    }
    return token;
  }

  public getPreAuthEmail(): string{
    let email = this.getItem(this.authEmailKey);
    //let remember_token = this.getObject(this.rememberTokenKey);
    if(!email){
      return null;
    }
    return email;
  }

  public getGuestUser(): boolean {
    // cehck local storage for key
    if(this.has('csGuestUserData') || this.has('stokkupGuestUserData')){
      return true;
    }
    return false;
  }

  public getGuestType(){
    if(this.has('csGuestUserData')){
      return 'csGuestUserData';
    }
    else if(this.has('stokkupGuestUserData')){
      return 'stokkupGuestUserData';
    }
    return null;
  }

  public getGuestUserName(): IUserGuest|null {
    if(this.has('csGuestUserData') || this.has('stokkupGuestUserToken')){
      let userKeyGuest = this.has('csGuestUserData') ? 'csGuestUserData' : 'stokkupGuestUserToken';
      let user = EntityBuilder.buildOne<IUserGuest>(IUserGuest, this.getObject(userKeyGuest));
      return user;
    }
    return null;
  }

  public flushUser():void{
    this.removeItem(this.userKey);
    this.removeItem(this.authTokenKey);
    this.removeItem(this.rememberTokenKey);
    this.removeItem(this.authEmailKey);
    this.removeItem(this.activeDecoratorKey);
    this.removeItem(this.activeClientKey);
  }

}
