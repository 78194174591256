import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {Observable} from "rxjs/index";
import {AuthService} from "./auth.service";
import {Injectable} from "@angular/core";
import {SessionService} from "../session/session.service";

@Injectable({ providedIn: 'root' })
export class AuthHeaderService implements HttpInterceptor{

    constructor(protected authService: AuthService, protected sessionService: SessionService){
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let currentUser = this.authService.getUser();
        let activeClient = this.sessionService.getActiveClient();
        let activeDecorator = this.sessionService.getActiveDecorator();
        if (currentUser && currentUser.token) {
            if(!request.url.includes('addresses/create_and_verify') && !request.url.includes('shipments')) {
                let headers = {
                    setHeaders: {
                        'CP-User-Id': `${currentUser.id}`,
                        'CP-User-Email': `${currentUser.email}`,
                        'CP-User-Token': `${currentUser.token}`,
                        'CP-Affiliate': `${currentUser.isAffiliateUser}`,
                        'Authorization': `Bearer ${currentUser.token}`,
                        'X-Requested-With': 'XMLHttpRequest',
                        // 'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        'Access-Control-Allow-Methods': 'POST, GET, OPTIONS, PUT',
                        'Accept': 'application/json',
                    }
                };
    
                if(!!activeClient){
                    headers.setHeaders['CP-Client-Id'] = `${activeClient.id}`;
                }
    
                if(!!activeDecorator){
                    headers.setHeaders['CP-Decorator-Id'] = `${activeDecorator.id}`;
                }
    
                request = request.clone(headers);
            }
        }
        else{
            // reject the request? or let the API handle it
        }

        return next.handle(request);
    }

}
