import {LoaderState} from "./loader-state";
import {Subject} from "rxjs";
import {Injectable} from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class HttpLoaderService{

  private loaderSubject:Subject<LoaderState>;
  public loaderState;


  constructor() {
    this.loaderSubject = new Subject();
    this.loaderState = this.loaderSubject.asObservable();
  }

  show() {
    this.loaderSubject.next(<LoaderState>{ show: true });
  }

  hide() {
    this.loaderSubject.next(<LoaderState>{ show: false });
  }
}