import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './core/header/header.component';
import { BaseComponent } from './core/base/base.component';
import { AboutStokkupComponent } from './features/about/about-stokkup/about-stokkup.component';
import { PrivacyPolicyComponent } from './features/about/privacy-policy/privacy-policy.component';
import { TermsOfServiceComponent } from './features/about/terms-of-service/terms-of-service.component';
import { SupportComponent } from './features/about/support/support.component';
import { AboutBaseComponent } from './features/about/about-base/about-base.component';
import { AuthHeaderService } from "./core/services/auth/auth-header.service";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { SessionService } from "./core/services/session/session.service";
import { AuthService } from "./core/services/auth/auth.service";
import { ApiService } from "./core/services/api/api.service";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { WindowEventsService } from "./core/services/session/window-events.service";
import { HttpLoaderComponent } from './core/http-loader/http-loader.component';
import { HttpLoaderInterceptorService } from "./core/services/api/http-loader-interceptor.service";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MdbModule } from './core/mdb.module';
import { DesignCardComponent } from './templates/design-card/design-card.component';
import { GlobalService } from './core/services/api/global.service';
import { HelperService } from "./core/services/api/helper.service";
import { SharedModule } from "./shared/shared.module";
import { IframeTemplateDialogComponent } from './templates/dialog/iframe-template-dialog/iframe-template-dialog.component';
import { ApolloModule, APOLLO_OPTIONS } from "apollo-angular";
import { HttpLink } from 'apollo-angular/http';
import { createApollo } from "./graphql.module";
import { GraphQlService } from "./core/services/api/graphql.service";
import { ProductCardComponent } from './templates/product-card/product-card.component';
import { ToastsComponent } from './templates/toasts/toasts.component';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { EffectsModule } from '@ngrx/effects';
import { Action, State, StoreModule } from '@ngrx/store';
import { AuthEffects } from './core/state/auth/auth.effects';
import { NgrxRouterStoreModule } from './core/state/router/ngrx-router.module';

import { authReducer } from './core/state/auth/auth.reducers';
import { userReducer } from './core/state/user/user.reducers';
import { UserEffects } from './core/state/user/user.effects';
import { orderReducer } from './core/state/order/order.reducers';
import { OrderEffects } from './core/state/order/order.effects';
import { DashboardEffects } from './core/state/dashboard/dashboard.effects';
import { dashboardReducer } from './core/state/dashboard/dashboard.reducers';
import { ClientEffects } from './core/state/client/client.effects';
import { clientReducer } from './core/state/client/client.reducers';
import { CatalogEffects } from './core/state/catalog/catalog.effects';
import { catalogReducer } from './core/state/catalog/catalog.reducers';
import { CartItemComponent } from '../app/templates/cart-item-template/cart-item-template.component';
import { CartModule } from './features/cart/cart.module';
import { DesignToolEffects } from '@core/state/design-tool/design-tool.effects';
import { designToolReducer } from '@core/state/design-tool/design-tool.reducers';

import { ErrorComponent } from './features/error/error.component';

// import { reducers } from '@core/state';

function actionSanitizer(action: Action) {
  // for hide all actions payload but still wanna trace action dispatched
  return environment.production ? {type: 'PROD ' + action.type} : action;
}
function stateSanitizer(state: State<any>) {
  // if you wanna hide the state
  return environment.production ? {} : state;
}


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    BaseComponent,
    AboutStokkupComponent,
    PrivacyPolicyComponent,
    TermsOfServiceComponent,
    SupportComponent,
    AboutBaseComponent,
    HttpLoaderComponent,
    DesignCardComponent,
    IframeTemplateDialogComponent,
    ProductCardComponent,
    ToastsComponent,
    CartItemComponent,
    ErrorComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    BrowserAnimationsModule,
    MdbModule,
    ReactiveFormsModule,
    SharedModule,
    CartModule,
    EffectsModule.forRoot([AuthEffects, UserEffects, OrderEffects, DashboardEffects, ClientEffects, CatalogEffects, DesignToolEffects]),
    StoreModule.forRoot(
      { auth: authReducer, user: userReducer, order: orderReducer, dashboard: dashboardReducer, client: clientReducer, catalog: catalogReducer, designTool: designToolReducer},
      {
        // runtimeChecks: {
        //   strictStateImmutability: true,
        //   strictActionImmutability: true,
        //   strictStateSerializability: true,
        //   strictActionSerializability: true,
        // },
      }),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),
    // StoreRouterConnectingModule.forRoot(),
    NgrxRouterStoreModule,
    ApolloModule
],
  exports: [

  ],
  providers: [
      SessionService,
      AuthService,
      ApiService,
      GlobalService,
      HelperService,
      GraphQlService,
      WindowEventsService,
      { provide: HTTP_INTERCEPTORS, useClass: AuthHeaderService, multi: true },
      { provide: HTTP_INTERCEPTORS, useClass: HttpLoaderInterceptorService,  multi: true },
      { provide: APOLLO_OPTIONS, useFactory: createApollo, deps: [HttpLink]},
],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
