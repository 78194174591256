import { createReducer, on } from "@ngrx/store"
import { getDesignLibrarySuccess, getDesignLibraryFailure, setSearchTerm, setImageDesign, setActivePosition, getClientDesignsSuccess, getClientDesignsFailure, setDesignObject, updateDesignPositionImage, updateDesinPositionObject, deleteDesignPositionObject, updateDesignNameObject, updateDesignProductVariantsObject, addDesignSuccess, getDesignToolMockupsSuccess, getDesignToolMockupsFailure, setCatalogCurrentProduct, getCatalogProductDetailsSuccess, updateDesignPositionCanvasImage, getDesignToolCanvasSuccess, getDesignToolCanvasFailure, getDesignToolCanvasAvailableSuccess, getDesignToolCanvasNotAvailableFailure, updateDesignPositions, setMobileView, setScaleFactor  } from "@core/state/design-tool/design-tool.actions"
import { S3Files, PositionsDesign, Designs, VariantImages, DesignToolCanvas, DesignToolAvailable } from "@core/models/designTool";
import { CatalogProductDetails } from "@core/models/products";

export interface State {
    catalogCurrentProductDetails?: CatalogProductDetails;
    searchTerm: string,
    imageDesign: string,
    designs: PositionsDesign[],
    clientDesigns: Designs[],
    clientDesignsLoaded: boolean,
    files: S3Files[],
    designMockups: VariantImages,
    activePosition: string,
    canvasDimensions: DesignToolCanvas,
    canvasDimensionsAvailable: DesignToolAvailable[],
    canvasDimensionsError?: string,
    designObject?: Designs,
    mobileView?: boolean;
    scaleFactor?: number;
}

export const initialState: State = {
    catalogCurrentProductDetails: null,
    searchTerm: null,
    imageDesign: null,
    designs: [],
    clientDesigns: null,
    clientDesignsLoaded: false,
    files: [],
    designMockups: null,
    activePosition: 'front',
    canvasDimensions: null,
    canvasDimensionsAvailable: null,
    canvasDimensionsError: null,
    designObject: null,
    mobileView: false,
    scaleFactor: null
}

const _designToolReducer = createReducer(
    initialState,
    on(getDesignLibrarySuccess, (state, { designLibraryResponse }) => {
        return {
            ...state,
            files: designLibraryResponse
        }
    }),
    on(getDesignLibraryFailure, (state, { error }) => {
        return {
            ...state,
            files: [],
        }
    }),
    on(getDesignToolMockupsSuccess, (state, { mockupImagesResponse }) => {
        return {
            ...state,
            designMockups: mockupImagesResponse
        }
    }),
    on(getDesignToolMockupsFailure, (state, { error }) => {
        return {
            ...state,
            designMockups: null
        }
    }),
    on(getDesignToolCanvasSuccess, (state, { designToolCanvasResponse }) => {
        return {
            ...state,
            canvasDimensions: designToolCanvasResponse
        }
    }),
    on(getDesignToolCanvasAvailableSuccess, (state, { designToolCanvasAvailable }) => {
        return {
            ...state,
            canvasDimensionsAvailable: designToolCanvasAvailable
        }
    }),
    on(getDesignToolCanvasNotAvailableFailure, (state, { error }) => {
        return {
            ...state,
            canvasDimensionsError: error
        }
    }),

    on(getDesignToolCanvasFailure, (state, { error }) => {
        return { ...state, canvasDimensions: null }
    }),
    on(setSearchTerm, (state, { searchTerm }) => {
        return { ...state, searchTerm }
    }),
    on(setImageDesign, (state, { imageDesign }) => {
        return { ...state, imageDesign }
    }),
    on(setActivePosition, (state, { activePosition }) => {
        return { ...state, activePosition }
    }),
    on(getClientDesignsSuccess, (state, { clientDesignsResponse }) => {
        return {
            ...state,
            clientDesigns: clientDesignsResponse,
            clientDesignsLoaded: true
        }
    }),
    on(getClientDesignsFailure, (state , { error }) => {
        return {
            ...state,
            clientDesigns: [],
            clientDesignsLoaded: true
        }
    }),
    on(setDesignObject, (state, { designObject }) => {
        return {
            ...state,
            designObject: designObject
        }
    }),
    on(setCatalogCurrentProduct, (state, { product }) => {
      return {
          ...state,
          catalogCurrentProductDetails: product
      }
    }),
    on(getCatalogProductDetailsSuccess, (state, { product }) => {
      return {
          ...state,
          catalogCurrentProductDetails: product
      }
    }),
    on(updateDesignPositionImage, (state, { position, design }) => {
        const updatedPositions = state.designObject.positions.map(pos => {
            if(pos.position === position){
                return {
                    ...pos,
                    canvas: [...(pos.canvas || []), design]
                }
            }
            return pos;
        });
        return {
            ...state,
            designObject: {
                ...state.designObject,
                positions: updatedPositions
            }
        }
    }),
    on(updateDesinPositionObject, (state, { position, design }) => {
        const updatedPositions = state.designObject?.positions?.map(pos => {
            if(pos.position === position){
                return {
                    ...pos,
                    canvas: design
                };
            }
            return pos;
        });
        return {
            ...state,
            designObject: {
                ...state.designObject,
                positions: updatedPositions
            }
        };
    }),
    on(updateDesignPositionCanvasImage, (state, { updates }) => {
        const updatedPositions = state.designObject?.positions?.map(pos => {
            // Find the update for this position
            const update = updates.find(update => update.position === pos.position);
            // If an update is found, apply it
            return update ? { ...pos, url: update.url, mockupUrl: update.mockupUrl } : { ...pos };
        });
        return {
            ...state,
            designObject: {
                ...state.designObject,
                positions: updatedPositions
            }
        };
    }),
    on(deleteDesignPositionObject, (state, { position, imageUrl }) => {
        return {
            ...state,
            designObject: {
                ...state.designObject,
                positions: state.designObject.positions.map(pos => {
                    if (pos.position === position) {
                        // Filter out the image with the specified URL
                        const updatedCanvas = pos.canvas.filter(image => image.url !== imageUrl);
                        return { ...pos, img: pos.url || null, canvas: updatedCanvas };
                    }
                    return pos;
                })
            }
        };
      }),
      on(updateDesignNameObject, (state, { name }) => {
        return {
            ...state,
            designObject: {
                ...state.designObject,
                name: name,
            }
        }
    }),
    on(updateDesignProductVariantsObject, (state, { variants }) => {
        return {
          ...state,
          designObject: {
            ...state.designObject,
            products: [
              {
                ...state.designObject.products[0], // Copy other properties of the product
                variants: variants,
              },
              ...state.designObject.products.slice(1), // Copy the rest of the products
            ],
          },
        };
    }),
    on(addDesignSuccess, (state, { designResponse }) => {
        return {
            ...state,
            designObject: designResponse
        }
    }),
    on(updateDesignPositions, (state, { designId, positions }) => {
        return {
            ...state,
            designObject: {
                ...state.designObject,
                positions
            }
        };
    }),
    on(setMobileView, (state, { mobileView }) => {
        return {
            ...state,
            mobileView
        }
    }),
    on(setScaleFactor, (state, { scaleFactor }) => {
        return {
            ...state,
            scaleFactor
        }
    })
)

export function designToolReducer(state, action) {
    return _designToolReducer(state,action)
}
