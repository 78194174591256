import { Injectable } from '@angular/core';
import { Router, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import * as UserActions from '@core/state/user/user.actions';
import * as fromUsers from '@core/state/user/user.selector';

@Injectable({
  providedIn: 'root'
})
export class UserResolver  {
  constructor(private store: Store){
    this.store.dispatch(UserActions.getUserInfo());
  }
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    let user;
    this.store.pipe(select(fromUsers.selectUser)).subscribe((u) => user = u)
    return of(user);
  }
}
