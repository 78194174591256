<section class="login-base">
  <div class="row login-base-card">
    <ng-container *ngIf="screenWidth > 390; else mobile"> 
      <div class="col-8 offset-2">
        <div class="card">
          <div class="card-body">
            <div class="login">
              <router-outlet></router-outlet>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</section>

<ng-template #mobile>
  <div class="col-10 offset-1">
    <div class="card">
      <div class="card-body">
        <div class="login">
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>
  </div>
</ng-template>