import { Injectable } from '@angular/core';
import {IUser, User} from "../../models/user";
import {SessionService} from "../session/session.service";
import {ApiService} from "../api/api.service";
import {Observable, of} from "rxjs";
import {ApiResponse} from "../api/api-response";
import {Router} from "@angular/router";
import { EntityBuilder } from '@decahedron/entity';
import {share} from "rxjs/operators";
import { IAuthResponse } from '../../models/auth';

@Injectable({ providedIn: 'root' })
export class AuthService{
  protected user: IUser = null;
  protected authToken: string = null;
  protected authEmail: string = null;
  protected rememberToken: boolean;

  constructor(protected session: SessionService, protected apiService: ApiService, protected router: Router) {
    //this.session.flushUser();
    this.user = session.getUser();
    this.authToken = session.getPreAuthToken();
    this.authEmail = session.getPreAuthEmail();
    this.rememberToken = !!this.authToken;
  }

  public isAuthenticated():boolean{
    return(!!this.user && !!this.user.token);
  }
  public isGuestUser():boolean{
    return this.session.getGuestUser();
  }

  /**
   * 
   * @returns Observable if user logged in / authenticated
   */
  public isLoggedIn():Observable<IUser>{
    let result = (!!this.user && !!this.user.token) ? of(this.user) : null
    return result;
  }
  /**
   * 
   * @returns Observable of user profile data (retrieved from local storage)
   */
  public getUserInfo():Observable<IUser>{
    return of(this.user);
  }

  public getUser():IUser{
    return this.user;
  }

  public tokenLogin(formData):Observable<Object>{
    let post$ = this.apiService.post('login/token', formData).pipe(share());
    post$.subscribe((response: ApiResponse) => {
      //success
      this.user = EntityBuilder.buildOne<User>(User, response.data);
      this.session.setUser(this.user);
    }, (response) => {

    });
    return post$;
  }

  public login(formData):Observable<Object>{
    let post$ = this.apiService.post('login/email', formData).pipe(share());

    post$.subscribe((response: ApiResponse) => {
      //success
      this.user = EntityBuilder.buildOne<User>(User, response.data);
      this.authToken = this.user.token;
      this.session.setUser(this.user);
    }, (response) => {

    });
    return post$;
  }

  public signup(formData):Observable<Object>{
    let post$ = this.apiService.post('login/signup', formData).pipe(share());
    post$.subscribe((response: ApiResponse) => {
      // TODO: Add email confirmation check
    }, (response) => {

    });
    return post$;
  }

  public csSignUp(formData):Observable<Object>{
    return this.apiService.post('login/cs-signup', formData).pipe(share());
  }

  public emailVerify(email): Observable<Object>{
    let formData = {email}
    let post$ = this.apiService.post('login/cs-email-verification', formData).pipe(share());
    post$.subscribe((response) => {
    }, (reponse) => {

    });
    return post$;
  }

  public logout(decorator): Observable<Object>{
    let vm = this;
    this.apiService.get('logout').subscribe((response) => {
      this.session.flushUser();
      this.user = null;
      this.authToken = null;
      this.authEmail = null;
      this.rememberToken = null;
      if(!!window['gapi'] && !!window['gapi'].auth2){
        window['gapi'].auth2.getAuthInstance().signOut().then(function () {
          vm.router.navigate(['/login']);
        });
      } else {
        this.router.navigate(['/login']);
      }
    })
    
    return of(true)
  }

  public accountValidationToken(token:string):Observable<Object>{
    return this.apiService.post('login/signup/confirm', {temp_token: token});
  }

  public resetPassword(formData):Observable<Object>{
    return this.apiService.post('login/email/reset', formData);
  }

  public resetPasswordConfirmation(formData):Observable<Object>{
    return this.apiService.post('login/email/reset/confirm', formData);
  }

  public attemptPreAuth():void{
    if(!!this.user){
      //this.router.navigate(['/orders'])
    }
    else if(this.rememberToken && !!this.authToken && !!this.authEmail){

      this.tokenLogin({
        email: this.authEmail,
        token: this.authToken
      }).subscribe(
        response => console.error(response)
      );
    }
    else{
      console.warn('no auth key combo available or user exists');
    }
  }

  public googleAuth(googleUser):Observable<Object>{
    let profile = googleUser.getBasicProfile();

    let apiUser = {
      google_token: googleUser.getAuthResponse().id_token,
      email: profile.getEmail(),
      first_name: profile.getGivenName(),
      last_name: profile.getFamilyName()
    };

    let $post = this.apiService.post('login/google', apiUser).pipe(share());
    $post.subscribe((response: ApiResponse) => {
      this.user = EntityBuilder.buildOne<User>(User, response.data);
      this.authToken = this.user.token;
      this.session.setUser(this.user);
    }, (response) => {
    });
    return $post;
  }

  async isTokenValid(token: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.apiService.getApiv2(`api/v2/cp-tokens/${token}`).subscribe({
        next: (response: boolean) => {
          resolve(response);
        },
        error: (error) => {
          console.error('Error validating token:', error);
          reject(error);
        }
      });
    });
  }
}
