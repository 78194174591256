import { Breakpoints } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { MdbNotificationConfig, MdbNotificationRef, MdbNotificationService } from 'mdb-angular-ui-kit/notification';
import { Page } from '../core/models/sidebar';
import { ToastsComponent } from '../templates/toasts/toasts.component';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  notificationRef: MdbNotificationRef<ToastsComponent> | null = null;
  /**
   * Below will be updated to add new pages
   */
   public pages: Page[] = [
    // {name: 'Dashboard', link: '/dashboard', icon: faHome},
    {name: 'Orders', link: '/orders', icon: 'list'},
    // {name: 'Art', link: '/art', icon: faList},
    // {name: 'Settings', link: '/settings', icon: faList}, 
  ]
  
  displayNameMap = new Map([
    [Breakpoints.XSmall, 'XSmall'],
    [Breakpoints.Small, 'Small'],
    [Breakpoints.Medium, 'Medium'],
    [Breakpoints.Large, 'Large'],
    [Breakpoints.XLarge, 'XLarge'],
  ]);

  showNotification (notification: MdbNotificationConfig){
    this.notificationRef?.close()
    this.notificationRef = this.notificationService.open(ToastsComponent, notification)
    let delay = 5000
    if (notification.autohide && notification.delay) {
      delay = +notification.delay
    }
    setTimeout(() => this.notificationRef.close(), delay)
  }

  constructor(
    private notificationService: MdbNotificationService
  ) {   }
}
