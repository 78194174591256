import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import {AuthService} from "./auth.service";
import { SessionService } from '../session/session.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService {
  requestedUrl: String;
  constructor(private auth: AuthService, private router: Router, private sessionService: SessionService) {}

  async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    if (this.auth.isAuthenticated()) {
      const user = this.auth.getUser();
      if(user && user.token){
        // get the token and chekc if its valid
         const isValid = await this.auth.isTokenValid(user.token);
        // if the token is valid, check if the user has all settings in place
         if (isValid) {
            this.requestedUrl = next.queryParamMap.get('redirect') ?? this.requestedUrl;
            // if the user has all settings in place, redirect to the saved requested url if exists
            if (this.requestedUrl?.length > 0 && this.sessionService.getActiveClient() && !state.url.includes('choose-client')) {
              this.requestedUrl = decodeURIComponent(this.requestedUrl.toString());
              const parts = this.requestedUrl.split('?');
              const route = parts?.[0];
              let queryParams = {};
              if (parts?.[1]) {
                const params = new URLSearchParams(parts?.[1]);
                params.forEach((val, key) => {
                  queryParams[key] = val;
                });
              }
              this.router.navigate([route], {queryParams});
              this.requestedUrl = undefined;
              return Promise.resolve(true);
            }
            return Promise.resolve(true);
         }
         // user is logged in but token is not valid, so user needs to login again
         this.router.navigate(['/login']);
      }else {
        this.router.navigate(['/login']);  
      }

    } else if (this.auth.isGuestUser()) {
      this.router.navigate(['/catalog']);
      return Promise.resolve(false);
    } else {
      // user is not logged in, so we save the requested url in memory
      this.requestedUrl = this.requestedUrl ?? state.url.toString();
      // and navigate to login page
      this.router.navigate(['/login'], {
        queryParams: {
          redirect: this.requestedUrl
        }
      });
      return Promise.resolve(false);
    }
  }
}
