import { Injectable } from '@angular/core';
import { Router, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { AuthService } from '@core/services/auth/auth.service';
import { Store, select } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { take, tap, switchMap } from 'rxjs/operators';
import * as fromUser from '@core/state/user/user.selector';
import * as CatalogActions from '@core/state/catalog/catalog.actions';
import { SessionService } from '@core/services/session/session.service';

@Injectable({
  providedIn: 'root'
})
export class BaseNavResolver  {
  public authenticated: boolean;
  public isAffiliate: any;
  public clientId: any;

  constructor(
    private store: Store,
    protected session: SessionService,
    protected auth: AuthService,
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    this.authenticated = this.auth.isAuthenticated();

    if (this.authenticated) {

      return this.store.pipe(
        select(fromUser.selectUserIsAffiliate),
        take(1),
        tap(user => {
          this.isAffiliate = user;
        }),
        switchMap(() => {
          if (this.isAffiliate === 0) {
            this.clientId = this.session.getActiveClient().id;
            this.store.dispatch(CatalogActions.getCarts({ clientId: this.clientId }));
          }
          return of(true);
        })
      );
    } else {
      return of(true);
    }
  }
}
