import {Injectable} from "@angular/core";
import { map, Observable, of, share, Subject } from "rxjs";
import { environment } from "src/environments/environment";
import { includes } from "underscore";
import { OrderResponse } from "../../models/order";
import { ApiService } from "./api.service";
import { DashboardMetrics } from "./dashboard-metrics";

@Injectable({ providedIn: 'root' })

export class GlobalService {
    public productionSlugs = [142,143,144,145,146,147,148,149,150]
    public artSlugs = [138,139,140,141]

    public sideNavState$: Subject<boolean> = new Subject()

    constructor(
        protected apiService: ApiService
    ){}

    public formatMetricsResponse(data:DashboardMetrics[]){
        let sData = [];
        let pCounts = 0;
        let aCounts = 0
        data.map(metric => {
            if(this.productionSlugs.includes(metric.slug)){
                pCounts += metric.counts
            }else if(this.artSlugs.includes(metric.slug)){
                aCounts += metric.counts
            }
            else{
                let item = {'label': metric.label, 'counts': metric.counts, 'priority': metric.priority_order}
                sData.push(item)
            }
            return;
        })

        sData.push({'label': 'Production', 'counts': pCounts, 'priority': 3}, {'label': 'Art', 'counts': aCounts, 'priority': 2})
        // SORTING THE DATA BASED ON THE PRIORTY LEVEL
        sData.sort((a,b) => {
            if(a.priority < b.priority){
                return -1
            }else if(a.priority > b.priority){
                return 1
            }
            return 0
        })
        return sData;
    }

    public getClientContacts(clientId): Observable<Object> {
        let contacts$ = this.apiService.getV2('api/v2/client-contact/'+clientId).pipe(share());
        return contacts$;
    }

    public createClientContact(contact): Observable<Object> {
        let contact$ = this.apiService.postV2('api/v2/client-contact/create-contact', contact).pipe(share());
        return contact$;
    }



}