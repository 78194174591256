import { createFeatureSelector, createReducer, createSelector, on } from "@ngrx/store";
import { IClient } from "../../models/client";
import { IDecorator } from "../../models/decorator";
import { IUser } from "../../models/user";
import { getUserDecoratorSuccess,getUserDecoratorFailure, getUserInfoFailure,getUserInfoSuccess, getUserClientSuccess, getUserClientFailure, setUserInfoSucccess, setUserDecoratorSuccess, setUserDecoratorFailure, setUserClientSuccess, setUserClientFailure, setUserClientContextSuccess, setUserClientContextFailure, getAllUserClientsSuccess, getAllUserClientsFailure, getAllUserDecoratorsFailure, getAllUserDecoratorsSuccess, getAllAffiliateDecoratorsSuccess, getAllAffiliateDecoratorsFailure } from "./user.actions";

export const featureName = "user";

export interface State {
    profile?: IUser,
    decorators?: [IDecorator],
    clients?:[IClient],
    decorator?: IDecorator,
    decoratorId?: number,
    client?: IClient,
    clientId?: number,
    userError?: string,
    decoratorError?:string,
    clientError?:string,
    clientContextError?: string
}

export const initialState: State = {
    profile: null,
    decorators: null,
    clients: null,
    decorator: {
        id:100015,
        name:"Culture Studio",
        address:"1151 W 40th street ",
        city:"Chicago",
        state:"IL",
        postalCode:"60609",
        phone:"3122438304",
        email:"rich@culturestudio.net",
        avatarImg:"http://app.stokkup.local/api/public/uploads/100015/staff/17/staff-logo-1590857842.png",
        avatarTwoChar:"CS"
    },
    client: null,
    clientId: null,
    decoratorId: 100015
}

const _userReducer = createReducer(
    initialState,
    on(getUserInfoSuccess, (state, {userResponse}) => {
        return {
            ...state,
            profile: userResponse
        }
    }),
    on(getUserInfoFailure, (state, {error}) => {
        return {
            ...state,
            userError: error,
            profile: null
        }
    }),
    on(getAllUserClientsSuccess, (state, {userClients}) => {
        return {
            ...state,
            clients: userClients.data
        }
    }),
    on(getAllUserClientsFailure, (state, {error}) => {
        return {
            ...state,
            clients: null,
            clientError: error
        }
    }),
    on(getAllAffiliateDecoratorsSuccess, (state, {affiliateDecorators}) => {
        return {
            ...state,
            decorators: affiliateDecorators.data
        }
    }),
    on(getAllAffiliateDecoratorsFailure, (state, {error}) => {
        return {
            ...state,
            decorators: null,
            decoratorError: error
        }
    }),
    on(getAllUserDecoratorsSuccess, (state, {userDecorators}) => {
        return {
            ...state,
            decorators: userDecorators.data
        }
    }),
    on(getAllUserDecoratorsFailure, (state, {error}) => {
        return {
            ...state,
            decorators: null,
            decoratorError: error
        }
    }),
    on(getUserDecoratorSuccess, (state, {userDecorator}) => {
        return {
            ...state,
            decorator: userDecorator,
            decoratorId: userDecorator.id
        }
    }),
    on(getUserDecoratorFailure, (state, {error}) => {
        return {
            ...state,
            decorator: null,
            decoratorError: error
        }
    }),
   
    on(getUserClientSuccess, (state, {userClient}) => {
        return {
            ...state,
            client: userClient,
            clientId: userClient ? userClient.id : null
        }
    }),
    on(getUserClientFailure, (state, {error}) => {
        return {
            ...state,
            client: null,
            clientError: error
        }
    }),
    on(setUserDecoratorSuccess, (state, {userDecorator}) => {
        return {
            ...state, 
            decorator: userDecorator
        }
    }),
    on(setUserDecoratorFailure, (state, {error}) => {
        return {
            ...state,
            decorator: null,
            decoratorError: error
        }
    }),
    on(setUserClientSuccess, (state, {userClient}) => {
        return {
            ...state,
            client: userClient
        }
    }),
    on(setUserClientFailure, (state, {error}) => {
        return {
            ...state,
            client: null,
            clientError: error
        }
    }),
    on(setUserClientContextSuccess, (state, {userClientContext}) => {
        return {
            ...state,
            clientId: userClientContext.client_id,
            decoratorId: userClientContext.decorator_id
        }
    }),
    on(setUserClientContextFailure, (state, {error}) => {
        return {
            ...state,
            clientId: null,
            decoratorId: null,
            clientContextError: error
        }
    })
)

export function userReducer(state, action){
    return _userReducer(state, action)
}


// selec
// export const selectUserState = createFeatureSelector<State>('user');
// export const selectUser = createSelector(selectUserState, state => state.user);
// export const selectIsLoggedIn = createSelector(selectUserState, state => state.isLoggedIn );
// export const selectDecorator = createSelector(selectUserState, state => state.decorator );

