import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MdbModule } from '../../core/mdb.module';
import { CartRoutingModule } from './cart-routing-module';
import { CartVariantTableModalComponent } from './cart-variant-table-modal/cart-variant-table-modal.component';
import { CartCheckoutComponent } from './cart-checkout/cart-checkout.component';
import { CartBaseComponent } from './cart-base/cart-base.component';


@NgModule({
  declarations: [
    CartBaseComponent,
    CartVariantTableModalComponent,
    CartCheckoutComponent,
  ],
  imports: [
    CommonModule,
    CartRoutingModule,
    FormsModule,
    SharedModule,
    ReactiveFormsModule,
    MdbModule
  ]
})
export class CartModule { }
