import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromDesignTool from '@core/state/design-tool/design-tool.reducers';
import { selectProductVariantColor }from '@core/state/catalog/catalog.selectors';
import { PositionDesign, TransformedFiles } from '@core/models/designTool';

export const selectDesignToolState = createFeatureSelector<fromDesignTool.State>('designTool');
export const selectDesignLibrary = createSelector( selectDesignToolState, state => state.files);
export const selectSearchTerm = createSelector( selectDesignToolState, state => state.searchTerm); // get search term from search bar
export const selectImageDesign = createSelector( selectDesignToolState, state => state.imageDesign); // get image url set
export const selectCurrentPosition = createSelector( selectDesignToolState, state => state.activePosition); // get current position
export const selectCanvasDimensions = createSelector( selectDesignToolState, state => state.canvasDimensions); // get canvas dimensions
export const selectClientDesigns = createSelector( selectDesignToolState, state => state.clientDesigns);
export const selectDesignObject = createSelector( selectDesignToolState, state => state.designObject);
export const selectDesignToolMockups = createSelector( selectDesignToolState, state => state.designMockups);
export const selectCatalogCurrentProductDetails = createSelector( selectDesignToolState, state => state.catalogCurrentProductDetails);
export const selectDesignToolAvialable = createSelector( selectDesignToolState, state => state.canvasDimensionsAvailable);
export const selectMobileView = createSelector( selectDesignToolState, state => state.mobileView);
export const selectScaleFactor = createSelector( selectDesignToolState, state => state.scaleFactor);

export const selectDesignFilesTransformed = createSelector(
  selectDesignLibrary,
    files => {
        if (!files || files.length === 0) return [];

        return files.map(item => ({
            imageUrl: `https://img.culturestudio.app/${item.Key}`,
            id: item.Key,
            name: item.Key.split('/').pop().split('.')[0]
        } as TransformedFiles))
    }
);

export const selectFilteredDesignFiles = createSelector(
    selectDesignFilesTransformed,
    selectSearchTerm,
    (designFiles, searchTerm) => {
        if (!searchTerm || searchTerm.trim() === '') {
            return designFiles;
        }

        return designFiles.filter(file =>
            file.id.toLowerCase().includes(searchTerm.toLowerCase())
        );
    }
);

export const selectDesignToolPositions = createSelector(
    selectDesignObject,
    selectCanvasDimensions,
    selectProductVariantColor,
    (designObject, canvasDimensions, variant) => {
        return designObject?.positions?.map(e => {
          const newE = {
            ...e,
            canCustomize: !!canvasDimensions?.[e.position],
            canvas: e.canvas?.map((_e: PositionDesign) => {
              return {..._e, name: _e.url.split('/').pop()};
            })
          }
          return newE
        });
    }
)

export const selectCurrentPositionData = createSelector(
    selectDesignObject,
    selectCurrentPosition,
    (positions, selectedPosition) => {
        return positions?.positions?.find(p => p.position === selectedPosition);
    }
)

export const selectCanCustomize = createSelector(
  selectCurrentPosition,
  selectCanvasDimensions,
  selectProductVariantColor,
  selectDesignObject,
  (position, canvasDimensions, variant, designObject) => {
    if (variant?.[0]?.colorName?.length > 0 && canvasDimensions && position?.length > 0 && designObject?.positions?.find(e => e.position == position)) {
      return !!canvasDimensions?.[position]
    }
    return undefined
  }
)
