import { createReducer, on } from "@ngrx/store"
import { IDashboardMapResponse, IDashboardMetrics, IDashboardMetricsResponse } from "../../models/dashboard"
import { getDashboardMetricsSuccess, getDashboardMetricsFailure, getDashboardMetricsProduction, getDashboardMetricsProductionSuccess, getDashboardMetricsProductionFailure, getDashboardMetricsEstimateSuccess, getDashboardMetricsEstimateFailure, getDashboardMetricsClosedFailure, getDashboardMetricsClosedSuccess, getDashboardShippingMapSuccess, getDashboardShippingMapFailure } from "./dashboard.actions"

export interface State {
    estimateMetrics: any,
    estimateMetricsError?: string
    productionMetrics: any,
    productionMetricsError?: string
    closedMetrics: any,
    closedMetricsError?: string,
    shippingMap: [IDashboardMapResponse],
    shippingMapLoadedError?: string,
    shippingMapLoaded: boolean,
}

export const initialState: State = {
    estimateMetrics: null,
    productionMetrics: null,
    closedMetrics: null,
    shippingMap: null, 
    shippingMapLoaded: false,
}

const _dashboardReducer = createReducer(
    initialState,
    on(getDashboardMetricsEstimateSuccess, (state, { metricsResponse }) => {
        return {
            ...state,
            estimateMetrics: metricsResponse
        }
    }),
    on(getDashboardMetricsEstimateFailure, (state, { error }) => {
        return {
            ...state,
            estimateMetrics: null,
            estimateMetricsError: error
        }
    }),
    on(getDashboardMetricsProductionSuccess, (state, { metricsResponse }) => {
        return {
            ...state,
            productionMetrics: metricsResponse
        }
    }),
    on(getDashboardMetricsProductionFailure, (state, { error }) => {
        return {
            ...state,
            productionMetrics: null,
            productionMetricsError: error
        }
    }),
    on(getDashboardMetricsClosedSuccess, (state, { metricsResponse }) => {
        return {
            ...state,
            closedMetrics: metricsResponse
        }
    }),
    on(getDashboardMetricsClosedFailure, (state, { error }) => {
        return {
            ...state,
            closedMetrics: null,
            closedMetricsError: error
        }
    }),
    on(getDashboardShippingMapSuccess, (state, {shippingMapResponse}) => {
        return {
            ...state,
            shippingMap: shippingMapResponse,
            shippingMapLoaded: true
        }
    }),
    on(getDashboardShippingMapFailure, (state, {error}) => {
        return {
            ...state,
            shippingMapLoadedError: error
        }
    }),
)


export function dashboardReducer(state, action) {
    return _dashboardReducer(state,action)
}

