import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType, concatLatestFrom } from "@ngrx/effects";
import { catchError, firstValueFrom, map, mergeMap, of, switchMap, tap } from "rxjs";
import { Store } from "@ngrx/store";
import { ActivatedRoute, Router } from "@angular/router";

import { getUserInfo, getUserInfoSuccess, getUserInfoFailure, getUserDecoratorSuccess, getUserDecoratorFailure, getUserDecorator, getUserClient, getUserClientSuccess, getUserClientFailure, setUserDecorator, setUserDecoratorSuccess, setUserDecoratorFailure, setUserClient, setUserClientSuccess, setUserClientFailure, setUserClientContext, setUserClientContextSuccess, setUserClientContextFailure, getAllUserClients, getAllUserClientsSuccess, getAllUserClientsFailure, getAllUserDecorators, getAllUserDecoratorsSuccess, getAllUserDecoratorsFailure, getAllAffiliateDecorators, getAllAffiliateDecoratorsSuccess, getAllAffiliateDecoratorsFailure } from "src/app/core/state/user/user.actions";
import { IDecorator, IDecoratorResponse } from "../../models/decorator";

import { SessionService } from "../../services/session/session.service";
import { SharedService } from "src/app/shared/shared.service";
import { AuthService } from "../../services/auth/auth.service";

import * as fromUsers from '@core/state/user/user.selector';
import * as routeSelector from '@core/state/router/router-state.selectors';
import { ApiService } from "../../services/api/api.service";
import { IClient, IClientData, IClientResponse } from "../../models/client";

@Injectable()
export class UserEffects {
    getUserInfo$ = createEffect( () =>
        this.actions$.pipe(
            ofType(getUserInfo),
            mergeMap(() =>
                this.authService.getUserInfo()
                .pipe(
                    map((userResponse) => (getUserInfoSuccess({userResponse}))),
                    catchError((error) => of(getUserInfoFailure(error)))
                )
            )
        )
    )
    getUserDecorator$ = createEffect( () =>
        this.actions$.pipe(
            ofType(getUserDecorator),
            mergeMap(() =>
                this.sessionService.getUserDecorator()
                .pipe(
                    map((userDecorator) => (getUserDecoratorSuccess({userDecorator}))),
                    catchError((error) => of(getUserDecoratorFailure(error)))
                )
            )
        )
    )
    getClientDecorator$ = createEffect( () =>
        this.actions$.pipe(
            ofType(getUserClient),
            mergeMap(() =>
                this.sessionService.getUserClient()
                .pipe(
                    map((userClient) => (getUserClientSuccess({userClient}))),
                    catchError((error) => of(getUserClientFailure(error)))
                )
            )
        )
    )

    userSetDecorator$ = createEffect( () =>
        this.actions$.pipe(
            ofType(setUserDecorator),
            switchMap((action) =>
                this.sessionService.setActiveDecorator(action.decorator)
                .pipe(
                    map((userDecorator: IDecorator) =>
                        setUserDecoratorSuccess({userDecorator})
                    ),
                    catchError((error) => of(setUserDecoratorFailure({error})))
                ))
        )
    )

    userRedirectSetDecorator$ = createEffect( () =>

    this.actions$.pipe(

            ofType(setUserDecoratorSuccess),
            concatLatestFrom(() =>[ this.store.select(fromUsers.selectUser), this.store.select(routeSelector.getDecoratorRoute)]),
            tap(([action, user, router]) => {
                if(user && router) {
                    let affiliate = parseInt(user.isAffiliateUser)
                    let decoratorType = router
                    
                    affiliate == 1 ? this.router.navigate(['dashboard']) : decoratorType == 'cs' ? this.router.navigate([`login/cs/choose-client`]) : this.router.navigate([`error`]);
                }
            })
        ),
        { dispatch: false }
    )

    userSetDecoratorFailure$ = createEffect( () =>
        this.actions$.pipe(
            ofType(setUserDecoratorFailure),
            concatLatestFrom(() => this.store.select(fromUsers.selectDecorator)),
            tap(([action, state]) => {
                let error = action.error['error']
                let toastClass = 'bg-danger';
                let notification = {data: {title: error.message, class: toastClass}, autohide: true}
                this.sharedService.showNotification(notification);
            })
        ),
        { dispatch: false}
    )

    userGetAllClients$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getAllUserClients),
            switchMap((action) => this.api.get('choose/client/'+action.decoratorId)
                .pipe(
                    switchMap((userClients: IClientResponse) => [
                        getAllUserClientsSuccess({userClients})
                    ]),
                    catchError((error) => of(getAllUserClientsFailure({error})))
                )
            )
        )
    )

    affilaiteGetAllDecorators$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getAllAffiliateDecorators),
            switchMap((action) => this.api.get('choose/affiliate-decorator')
                .pipe(
                    map((affiliateDecorators: IDecoratorResponse) => getAllAffiliateDecoratorsSuccess({affiliateDecorators})),
                    catchError((error) => of(getAllAffiliateDecoratorsFailure({error})))
                )
            )
        )
    )

    userGetAllDecorators$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getAllUserDecorators),
            switchMap((action) => this.api.get('choose/decorator')
                .pipe(
                    map((userDecorators: IDecoratorResponse) => getAllUserDecoratorsSuccess({userDecorators})),
                    catchError((error) => of(getAllUserDecoratorsFailure({error})))
                )
            )
        )
    )

    userSetClient$ = createEffect( () =>
        this.actions$.pipe(
            ofType(setUserClient),
           switchMap((action) => this.sessionService.setActiveClient(action.client)
                .pipe(
                    switchMap((userClient: IClient) => [
                        setUserClientSuccess({userClient})
                    ]),
                    catchError((error) => of(setUserClientFailure({error})))
                )
           )
        )
    )

    userSetClientSuccess$ = createEffect( () =>
        this.actions$.pipe(
            ofType(setUserClientSuccess),
            concatLatestFrom(() => this.store.select(fromUsers.selectUserState)),
            tap(([action, state]) => {
                let client = state.client
                let decorator_id = state.decorator.id
                this.store.dispatch(setUserClientContext({client, decorator_id}))
            })

        ),
        { dispatch: false}
    )

    userSetClientContext$ = createEffect(() =>
        this.actions$.pipe(
            ofType(setUserClientContext),
            switchMap((action) => this.api.post('choose/set-context', {decorator_id: action.decorator_id, client_id: action.client.id})
                .pipe(
                    switchMap((userClientContext: IClientData) => [
                        setUserClientContextSuccess({userClientContext})
                    ]),
                    catchError((error) => of(setUserClientContextFailure({error})))
                )
            )
        )
    )

    userSetClientContextRedirect$ = createEffect( () =>
        this.actions$.pipe(
            ofType(setUserClientContextSuccess),
            tap(async () => {
                const queryParams = await firstValueFrom(this.route.queryParams)
                if (queryParams.redirect) {
                    this.router.navigateByUrl(queryParams.redirect)
                } else {
                    this.router.navigate(['/catalog'], {queryParams})
                }
            })
        ),
        { dispatch: false }
    )

    constructor(
        private actions$: Actions,
        private api: ApiService,
        private authService: AuthService,
        private sessionService: SessionService,
        private store: Store,
        private router:  Router,
        private route: ActivatedRoute,
        private sharedService: SharedService
    ){}
}
