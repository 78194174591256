import { Injectable } from "@angular/core";
import { Apollo, gql, QueryRef } from 'apollo-angular';

@Injectable({ providedIn: 'root' })

export class GraphQlService {
    
    public setQuery(search, brand, category, custom, decoration){
        if(search){
            return this.GET_SEARCH_CATALOG_PRODUCTS
        }else if(!search && brand){
            return this.GET_BRAND_PRODUCTS 
        }else if(!search && !brand && category){
            return this.fetchQuery('category')
        }else if(custom){
            return this.GET_CUSTOM_PRODUCTS
        }else if(!search && !brand && !category && !custom && decoration){
            return this.GET_DECORATION_SEARCH_CATALOG_PRODUCTS
        }else {
            return this.GET_CATALOG_PRODUCTS
        }

    }

    public setReturnQueryString(search, brand, category, custom, decoration){
        if(brand){
            return 'getProductsByBrandVersionIndex'
        }else if(search ){
            return 'getProductsVersionActiveIndex'
        }else if(custom){
            return 'getCustomProductsByCompanyIdVersionIndex'
        }else if(decoration){
            return 'getProductsVersionActiveIndex'
        }
        else{
            return 'getProductsByVersionFeatureIndex'
        }
    }
    public GET_CUSTOM_PRODUCTS = gql`
    query MyCustomProductsQuery($token: String){
        getCustomProductsByCompanyIdVersionIndex(nextToken: $token) {
            nextToken
            items {
                id
                version
                brand
                style
                category
                title
                latest
                featured
                custom
                company_id
                thumbnail
                vendor
            }
        }            
    }
`;
    public GET_CATALOG_PRODUCTS = gql`
        query MyQuery ($token: String, $search: String, $limit: Int){
            getProductsByVersionFeatureIndex(sortDirection: DESC, limit: $limit, filter: { and: [{is_active: {eq: "1"}}, {or: [ {brand: {contains: $search}},{style:{contains: $search}},{title: {contains: $search}},{category:{contains: $search}} ]} ] },nextToken: $token) {
                nextToken
                items {
                    id
                    version
                    brand
                    style
                    category
                    title
                    latest
                    featured
                    custom
                    company_id
                    thumbnail
                    vendor
                }
            }            
        }
    `;

    public GET_SEARCH_CATALOG_PRODUCTS = gql`
        query MyQuery ($token: String, $search: String, $limit: Int){
            getProductsVersionActiveIndex(sortDirection: DESC, limit: $limit, filter: {or: [{brand: {contains: $search}}, {style: {contains: $search}}, {title: {contains: $search}}, {category: {contains: $search}} {decoration: {contains: $search}} ]},nextToken: $token) {
                nextToken
                items {
                    id
                    version
                    brand
                    style
                    category
                    title
                    latest
                    featured
                    custom
                    company_id
                    thumbnail
                    vendor
                }
            }            
        }
    `;

    public GET_CATEGORIES = gql`
        query categories {
            listProductCategories {
                items {
                    uuid
                    name
                }
            }
        }
    `

    public GET_BRAND_PRODUCTS = gql`
        query productsByBrand ($brand: String!, $token: String!, $limit: Int, $search: String) {
            getProductsByBrandVersionIndex(brand: $brand, sortDirection: DESC,  limit: $limit, filter: {and:[{is_active: {eq:"1"}}, {or: [{style: {contains: $search}},{category: {contains: $search}}]} ]},nextToken: $token){
                items {
                    id
                    version
                    brand
                    style
                    category
                    title
                    latest
                    featured
                    custom
                    company_id
                    thumbnail
                    vendor
                }
                nextToken
            }
        }
    `
    public fetchQuery(key){
        if(key == 'category'){
            return gql`
                query products($token: String, $category: String){
                    getProductsByVersionFeatureIndex(sortDirection: DESC,nextToken: $token, filter: {and: [{category: {contains: $category}}]} ){
                        nextToken
                        items {
                            id
                            version
                            brand
                            style
                            category
                            title
                            latest
                            featured
                            custom
                            company_id
                            thumbnail
                            vendor
                        }
                        nextToken
                    }
                }
            `
        }
    }
    
    public GET_CATEGORY_PRODUCTS = gql`
        query MyQuery ($token: String, $search: String, $limit: Int){
            getProductsByVersionFeatureIndex(sortDirection: DESC, limit: $limit, filter: {or: [  {category: {contains: $search}}]},nextToken: $token) {
                nextToken
                items {
                    id
                    version
                    brand
                    style
                    category
                    title
                    latest
                    featured
                    custom
                    company_id
                    thumbnail
                    vendor
                }
            }            
        }
    `

    public GET_DECORATION_TYPES = gql`
        query decorations {
            listDecorations {
                items {
                id
                name
                }
            }
        }
    `
    public GET_DECORATION_SEARCH_CATALOG_PRODUCTS = gql`
        query MyQuery ($token: String, $search: String, $limit: Int){
            getProductsVersionActiveIndex(sortDirection: DESC, limit: $limit, filter: {or: [ {decoration: {contains: $search}} ]},nextToken: $token) {
                nextToken
                items {
                    id
                    version
                    brand
                    style
                    category
                    title
                    latest
                    featured
                    custom
                    company_id
                    thumbnail
                    vendor
                }
            }            
        }
    `;
}