import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { DesignToolPosition, Designs, VariantColors, VariantImages, DesignToolProduct, TransformedFiles } from "@core/models/designTool";
import { BehaviorSubject, Observable, from, of } from "rxjs";
import { environment } from 'src/environments/environment';
import { SessionService } from "../session/session.service";
import { HelperService } from "../api/helper.service";
import { CatalogProductDetails } from "@core/models/products";
import { IUser } from "@core/models/user";
import { FileUploadJob } from "@features/catalog/product-design/upload-art-modal/upload-art-modal.component";
import { Client } from "@core/models/client";

@Injectable({
    providedIn: 'root'
})

export class CatalogDesignService {
  private screenWidthSource = new BehaviorSubject<number>(window.innerWidth);
  screenWidth$ = this.screenWidthSource.asObservable();

    constructor(
        private http: HttpClient,
        private sessionService: SessionService,
        private readonly helperService: HelperService
    ){
      window.addEventListener('resize', () => {
        this.screenWidthSource.next(window.innerWidth);
      });
    }

    private baseURL = environment.apiMicroservice;

    getCurrentScreenWidth(): number {
      return window.innerWidth;
    }

    getDesignFilesFromS3(clientId?: number){
      if (!clientId) {
        return of(null)
      }
      const url = `${this.baseURL}aws/design/files`;

      const data = this.http.get(url, {params: {clientId}});

      return from(data);
    }

    getDesignToolCanvas(params){
      const { brand, style, color, width } = params;
      const url = `${this.baseURL}products/canvas-dimensions`;
        
      const data = this.http.get(url, {params: {brand, style, color, width}});
        
      return from(data);
    }

    getDesignToolCanvasAvailable(){
      const url = `${this.baseURL}products/canvas-available`;
     
      const data = this.http.get(url);
      return from(data);
    }

    getDesignToolMockups(product){

      const url = `${this.baseURL}aws/design/mockup/files`;

      const data = this.http.get(url, {params: product.product});

      return from(data);
    }

    getVariantData(brand, style){
        const url = `${this.baseURL}products/variant/design`;

        const data = this.http.get(url, {params: {brand, style}});
        return from(data);
    }

    getVariantColorData(brand, style, color, clientId, data, qty = 1){
      //check if quantities are entered, if not default 1
      const paramObj = data ? JSON.parse(data).map(d => {
        const positionData = { position: d.position };
        if (d.canvas) {
            positionData['canvas'] = d.canvas.map(c => ({
                colorCount: c.colorCount,
                decorationType: c.decorationType,
            }));
        }
        return positionData;
      }) : [];

      let params = paramObj.length ? { brand, style, color, clientId, data: JSON.stringify(paramObj), qty } : { brand, style, color, clientId, qty };

      const url = `${this.baseURL}marketplace/pricing/design-tool`;
      const result = this.http.get(url, {params: params });
      return from(result);
    }

    getDesignsByClient(clientId?: number){
      if (!clientId) {
        return of(null)
      }
        const url = `${this.baseURL}marketplace/designs/${clientId}`;

        const data = this.http.get(url);

        return from(data);
    }

    // upload file
  uploadFile(fileUploadJob: FileUploadJob) {
    const clientId = this.sessionService.getActiveClient().id;
    const formData: FormData = new FormData();
    formData.append('file', fileUploadJob.convertedFile ?? fileUploadJob.file, fileUploadJob.name);
    formData.append('clientId', clientId.toString());
    formData.append('fileExtension', fileUploadJob.fileExtension);
    const url = `${this.baseURL}aws/design/upload`;

    return this.http.post(url, formData, {
      reportProgress: true,
      observe: 'events',
      responseType: 'text'
    });
  }

    // upload design position mockups for client and design
    uploadDesignPositionMockups(formData: FormData){
      const url = `${this.baseURL}aws/design/positionMockup/files`;
      const data = this.http.post(url, formData);

      return from(data);
    }

    getAvalaraCustomer(customerCode){
        const url = `${this.baseURL}marketplace/carts/getCustomer/${customerCode}`;

        const data = this.http.get(url);

        return from(data);
    }

    // convert file
    convertFileSVG(fileUploadJob: FileUploadJob){
        const formData = new FormData();
        const fileExtension = fileUploadJob.file.name.split('.').pop().toLowerCase();
        formData.append('file', fileUploadJob.file, fileUploadJob.name);
        const api_key = environment.cloudMersiveKey
        const url = `https://api.cloudmersive.com/convert/image/${fileExtension}/to/svg`;
        const headers = {
            'ApiKey': api_key,
        }
        return this.http.post(url, formData, {
          reportProgress: true,
          headers: headers,
          observe: 'events',
          responseType: 'text'
        })
    }

  isProductCustomizable(brand: string, style: string, color: string, designToolData?: any): any {
   // provided the brand style color check if the product exists in designToolData
    return designToolData?.some(d => d.brand === brand && d.style === style && d.color === color);
  }

    // format design object
    formatDesignObject(name: string, item: VariantColors, user: IUser): Designs {
      const clientId = this.sessionService.getActiveClient().id;
      const productData: Partial<DesignToolProduct> = {
          title: item?.title || '',
          brand: item?.brand || '',
          style: item?.style || '',
          vendorStyleId: item?.vendorStyleId || null,
          decoratedImages: item ? this.helperService.getProductImages(item) as string[] : [],
          variants: []
      };
      const positions = item ? this.createPositions(item.images) : [];
      const createdBy = {
          id: user?.id,
          firstName: user?.firstName,
          lastName: user?.lastName,
          email: user?.email,
      };
      const formattedObject: Designs = {
          name: name,
          clientId: clientId,
          products: [productData],
          positions: positions,
          createdBy: createdBy
      };

      return formattedObject;
  }

    createPositions(item: VariantImages): Partial<DesignToolPosition>[] {
      const positions: Partial<DesignToolPosition>[] = [];

      for (const key in item) {
        if (item[key] !== null) {
          positions.push({ position: key });
        }
      }
      return positions;
  }

  getCatalogProductDetails(brand: string, style: string): Observable<CatalogProductDetails>  {
    const url = `${environment.apiMicroservice}products/product?brand=${encodeURIComponent(brand)}&style=${encodeURIComponent(style)}`;
    return this.http.get<CatalogProductDetails>(url, {params: {brand, style}})
  }

  removeFileFromDesignFiles(fileName: string, client: Client) {
    const clientId = client.id
    if (!clientId) {
      return of(null)
    }
    const url = `${this.baseURL}aws/design/files`;

    const data = this.http.delete(url, {
      params: {
        clientId,
        fileName
      }
    });

    return from(data);
  }
}
