import { createReducer, on } from "@ngrx/store";
import { IClient } from "../../models/client";
import { createClientContactFailure, createClientContactSuccess, getAvalaraClientFailure, getAvalaraClientSuccess, getClientContactsFailure, getClientContactsSuccess, getClientFailure, getClientSuccess } from "./client.actions";

export const featureName = "client";

export interface State {
    client: IClient,
    contacts: any,
    clientError?: string,
    contactsError?: string,
    avalaraClientId: any
    avalaraClientError?: string
}

export const initialState: State = {
    client: null,
    contacts: null,
    avalaraClientId: null,
    avalaraClientError: null
}

const _clientReducer = createReducer(
    initialState,
    on(getClientSuccess, (state, { client }) => {
        return {
            ...state,
            client: client
        }        
    }),
    on(getClientFailure, (state, { error }) => {
        return {
            ...state,
            clientError: error
        }        
    }),
    on(getClientContactsSuccess, (state, { clientContacts }) => {
        return {
            ...state,
            contacts: clientContacts
        }        
    }),
    on(getClientContactsFailure, (state, { error }) => {
        return {
            ...state,
            contactsError: error
        }        
    }),
    on(createClientContactSuccess, (state, {clientContact}) => {
        return {
            ...state,
           
            contacts: [...state.contacts, clientContact]
        }
    }),
    on(createClientContactFailure, (state, {error}) => {
        return {
            ...state,
            contactsError: error
        }
    }),
    on(getAvalaraClientSuccess, (state, {avalaraClient}) => {
        return {
            ...state,
            avalaraClientId: avalaraClient
        }
    }),
    on(getAvalaraClientFailure, (state, {error}) => {
        return {
            ...state,
            avalaraClientError: error
        }
    })
)

export function clientReducer(state, action){
    return _clientReducer(state, action)
}