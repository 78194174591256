import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-cart-item-template',
  templateUrl: './cart-item-template.component.html',
  styleUrls: ['./cart-item-template.component.scss']
})
export class CartItemComponent implements OnInit {
  @Input() carts: any;
  @Input() isOpen: boolean = false;


  constructor(
    protected router: Router,
  ) { }

  ngOnInit(): void {
  }
  

  calculateProductQty(products) {
    if(products) {
      // return products.length || 0
      return 1 // will always be 1 with current design tool build
    }
  }

  calculateSizeQty(products) {
    let totalQty = 0;
  
    if(products.variants) {
      for(const variant of products.variants) {
        totalQty += variant.qty
      }
    }

    return totalQty;
  }

}
