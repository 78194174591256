import { createAction, props } from "@ngrx/store";
import { IHeroResponse, IPopularCategoriesResponse, IPopularProductsResponse, IPopularBrandsResponse, ICatalogContentful, Cart, IFeaturedCollectionsResponse } from "../../models/catalog";
import { Client } from "../../models/client";
import { VariantColors } from "../../models/designTool";

export const getCatalogContentful = createAction(
    '[Catalog] Get Catalog Contentful Data ',
    props<{ payload: ICatalogContentful }>()

)

export const getContentfulHero = createAction(
    '[Catalog] Get Contentful Hero',
)
export const getContentfulHeroSuccess = createAction(
    '[Catalog] Get Contentful Hero Success',
    props<{ heroResponse: IHeroResponse }>()
)
export const getContentfulHeroFailure = createAction(
    '[Catalog] Get Contentful Hero Failure',
    props<{ error: string }>()
)

export const getContentfulDesignToolProducts = createAction(
    '[Catalog] Get Contentful DesignToolProducts ',
)
export const getContentfulDesignToolProductsSuccess = createAction(
    '[Catalog] Get Contentful DesignToolProducts Success',
    props<{ designToolResponse: any }>()
)
export const getContentfulDesignToolProductsFailure = createAction(
    '[Catalog] Get Contentful DesignToolProducts Failure',
    props<{ error: string }>()
)

export const getContentfulCategories = createAction(
    '[Catalog] Get Contentful Categories ',
)
export const getContentfulCategoriesSuccess = createAction(
    '[Catalog] Get Contentful Categories Success',
    props<{ categoryResponse: IPopularCategoriesResponse }>()
)
export const getContentfulCategoriesFailure = createAction(
    '[Catalog] Get Contentful Categories Failure',
    props<{ error: string }>()
)

export const getContentfulProducts = createAction(
    '[Catalog] Get Contentful Products',
)
export const getContentfulProductsSuccess = createAction(
    '[Catalog] Get Contentful Products Success',
    props<{ productResponse: IPopularProductsResponse }>()
)
export const getContentfulProductsFailure = createAction(
    '[Catalog] Get Contentful Products Failure',
    props<{ error: string }>()
)

export const getContentfulFeaturedCollections = createAction(
    '[Catalog] Get Contentful FeaturedCollections ',
)
export const getContentfulFeaturedCollectionsSuccess = createAction(
    '[Catalog] Get Contentful FeaturedCollections Success',
    props<{ featureCollectionResponse: IFeaturedCollectionsResponse }>()
)
export const getContentfulFeaturedCollectionsFailure = createAction(
    '[Catalog] Get Contentful FeaturedCollections Failure',
    props<{ error: string }>()
)

export const getContentfulBrands = createAction(
    '[Catalog] Get Contentful Brands ',
)
export const getContentfulBrandsSuccess = createAction(
    '[Catalog] Get Contentful Brands Success',
    props<{ brandResponse: IPopularBrandsResponse }>()
)
export const getContentfulBrandsFailure = createAction(
    '[Catalog] Get Contentful Brands Failure',
    props<{ error: string }>()
)

export const getContentfulSubcategories = createAction(
    '[Catalog] Get Contentful Subcategories',
)
export const getContentfulSubcategoriesSuccess = createAction(
    '[Catalog] Get Contentful Subcategories Success',
    props<{ subcategoriesResponse: any }>()
)
export const getContentfulSubcategoriesFailure = createAction(
    '[Catalog] Get Contentful Subcategories Failure',
    props<{ error: string }>()
)

export const getContentfulBanner = createAction(
    '[Catalog] Get Contentful Banner'
)
export const getContentfulBannerSuccess = createAction(
    '[Catalog] Get Contentful Banner Success',
    props<{ bannerResponse: any }>()
)
export const getContentfulBannerFailure = createAction(
    '[Catalog] Get Contentful Banner Failure',
    props<{ error: string }>()
)

export const getCatalogProducts = createAction(
    '[Catalog] Get Catalog Products',
    props<{ searchParam: any, currentPage: any, sort: any }>()
)
export const getCatalogProductsSuccess = createAction(
    '[Catalog] Get Catalog Products Success',
    props<{ productsResponse: any }>()
)
export const getCatalogProductsFailure = createAction(
    '[Catalog] Get Catalog Products Failure',
    props<{ error: string }>()
)

export const updateCatalogQuery = createAction(
    '[Catalog] Update Catalog Query',
    props<{ queryUpdate: any, currentPage: number, sort: string }>()
)
export const updateCatalogQuerySuccess = createAction(
    '[Catalog] Update Catalog Query Success',
    props<{ queryResponse: any }>()
)
export const updateCatalogQueryFailure = createAction(
    '[Catalog] Update Catalog Query Failure',
    props<{ error: string }>()
)

export const updateCatalogCategoriesNav = createAction(
    '[Catalog] Update Catalog Categories Nav',
    props<{ categories: any }>()
)
export const updateCatalogCategoriesNavSuccess = createAction(
    '[Catalog] Update Catalog Categories Nav Success',
    props<{ queryResponse: any }>()
)
export const updateCatalogCategoriesNavFailure = createAction(
    '[Catalog] Update Catalog Categories Nav Failure',
    props<{ error: string }>()
)

export const updateCatalogBrandsNav = createAction(
    '[Catalog] Update Catalog Brands Nav',
    props<{ brands: any }>()
)
export const updateCatalogBrandsNavSuccess = createAction(
    '[Catalog] Update Catalog Brands Nav Success',
    props<{ queryResponse: any }>()
)
export const updateCatalogBrandsNavFailure = createAction(
    '[Catalog] Update Catalog Brands Nav Failure',
    props<{ error: string }>()
)

// Get Product Variant based on brand, style
export const getProductVariant = createAction(
    '[Catalog] Get Product Variant',
    props<{ brand: any, style: any }>()
)

export const getProductVariantSuccess = createAction(
    '[Catalog] Get Product Variant Success',
    props<{ variantResponse: any }>()
)

export const getProductVariantFailure = createAction(
    '[Catalog] Get Product Variant Failure',
    props<{ error: string }>()
)

// Get Product Variant based on brand, style
export const getProductVariantColor = createAction(
    '[Catalog] Get Product Variant Color',
    props<{ brand: string, style: string, color: string, clientId?: number, data?: any, qty?:number }>()
)

export const getProductVariantColorSuccess = createAction(
    '[Catalog] Get Product Variant Color Success',
    props<{ variantResponse: VariantColors[] }>()
)

export const getProductVariantColorFailure = createAction(
    '[Catalog] Get Product Variant Color Failure',
    props<{ error: string }>()
)

// update pricing for product variant color
export const updatePricingProductVariantColor = createAction(
    '[Catalog] Update Pricing Product Variant Color',
    props<{ brand: string, style: string, color: string, clientId: number, data?: any, qty?:number }>()
)

export const updatePricingProductVariantColorSuccess = createAction(
    '[Catalog] Update Pricing Product Variant Color Success',
    props<{ variantResponse: VariantColors[] }>()
)

export const updatePricingProductVariantColorFailure = createAction(
    '[Catalog] Update Pricing Product Variant Color Failure',
    props<{ error: string }>()
) 

// Design Library Actions
export const getDesignLibrary = createAction(
    '[Catalog] Get Design Library',
    props<{ client: Client }>()
)

export const getDesignLibrarySuccess = createAction(
    '[Catalog] Get Design Library Success',
    props<{ designLibraryResponse: any }>()
)

export const getDesignLibraryFailure = createAction(
    '[Catalog] Get Design Library Failure',
    props<{ error: string }>()
)

// Carts Actions
export const getCarts = createAction(
    '[Catalog] Get Carts',
    props<{ clientId: Number }>()
)

export const getCartsSuccess = createAction(
    '[Catalog] Get Carts Success',
    props<{ cartsResponse: any }>()
)

export const getCartsFailure = createAction(
    '[Catalog] Get Carts Failure',
    props<{ error: string }>()
)


export const SetSearchTermAction = createAction(
    '[Catalog] Set Search Term',
    props<{ searchTerm: string }>()
)

export const addCart = createAction(
    '[Catalog] Add Cart',
    props<{ cart: Cart }>()
)

export const addCartSuccess = createAction(
    '[Catalog] Add Cart Success',
    props<{ cartResponse: any }>()
)

export const addCartFailure = createAction(
    '[Catalog] Add Cart Failure',
    props<{ error: string }>()
)