import { createAction, props } from '@ngrx/store';
import { IAuth, IAuthResponse, IPasswordResetConfirmationForm } from '../../models/auth';
import { IUser } from '../../models/user';
import { ApiResponse, IApiResponse } from '../../services/api/api-response';

export const authLoginRequest = createAction(
    '[Auth] Login Request',
    props<{ credentials: IAuth}>()
  );

  export const authLoginRequestSuccess = createAction(
    '[Auth] Login Success',
    props<{ loginSuccessResponse: IAuthResponse }>()
  );
  
  export const authLoginRequestFailure = createAction(
    '[Auth] Login Failure',
    props<{ error: string }>()
  );

  export const authGoogleLoginRequest = createAction(
    '[Auth] Google Login Request',
    props<{ credentials: any}>()
  );

  export const authGoogleLoginRequestSuccess = createAction(
    '[Auth] Google Login Success',
    props<{ loginSuccessResponse: any }>()
  );
  
  export const authGoogleLoginRequestFailure = createAction(
    '[Auth] Google Login Failure',
    props<{ error: string }>()
  );

  export const getAuthUser = createAction(
    '[Auth] Get Auth User'
  )
  export const getAuthUserSuccess = createAction(
    '[Auth] Get Auth User Success',
    props<{user:IUser}>()
  )
  export const getAuthUserFailure = createAction(
    '[Auth] Get Auth User Failure',
    props<{ error: string }>()
  )
  export const logoutUser = createAction(
    '[Auth] Logout User',
    props<{ decorator: string }>()
  )

  export const logoutUserSuccess = createAction(
    '[Auth] Logout User Success',
    props<{ status: boolean }>()
  )

  export const logoutUserFailure = createAction(
    '[Auth] Logout User Failure',
    props<{ error: string }>()
  )

  export const confirmUser = createAction(
    '[Auth] Confirm User Email',
    props<{ token: string }>()
  )

  export const confirmUserSuccess = createAction(
    '[Auth] Confirm User Email Success',
    props<{ response: IApiResponse }>()
  )

  export const confirmUserFailure = createAction(
    '[Auth] Confirm User Email Failure',
    props<{ error: string }>()
  )

  export const resetPassword = createAction(
    '[Auth] Reset User Password',
    props<{ email: string}>()
  )
  export const resetPasswordSuccess = createAction(
    '[Auth] Reset User Password Success',
    props<{ response: IApiResponse}>()
  )
  export const resetPasswordFailure = createAction(
    '[Auth] Reset User Password Failure',
    props<{ error: string}>()
  )

  export const submitResetPassword = createAction(
    '[Auth] Submit Reset User Password',
    props<{formData: IPasswordResetConfirmationForm}>()
  )

  export const submitResetPasswordSuccess = createAction(
    '[Auth] Submit Reset User PasswordSuccess',
    props<{ response: IApiResponse}>()
  )

  export const submitResetPasswordFailure = createAction(
    '[Auth] Submit Reset User PasswordFailure',
    props<{error: string}>()
  )