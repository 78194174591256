import { createFeatureSelector, createSelector } from "@ngrx/store";
import * as fromClient from '@core/state/client/client.reducers';

export const selectClientState = createFeatureSelector<fromClient.State>(fromClient.featureName);
export const selectClient = createSelector(selectClientState, state => state.client);
export const selectContacts = createSelector(selectClientState, state => state.contacts);
export const selectAvalaraClient = createSelector(selectClientState, state => state.avalaraClientId);

export const selectClientId = createSelector(selectClient, (data) => {
    let id: number | 0;
    if(data){
        id = data.id;
    }
    return id;
});
export const selectAMId = createSelector(selectClient, (data) => {
    let id: number | 0;
    if(data){
        id = data.account_manager;
    }
    return id;
});

export const selectSalesId = createSelector(selectClient, (data) => {
    let id: number | 0;
    if(data){
        id = data.salesperson;
    }
    return id;
});

export const selectPriceGridId = createSelector(selectClient, (data) => {
    let id: number | 0;
    if(data){
        id = data.salespricegrid;
    }
    return id;
});

