import { Type, Entity } from '@decahedron/entity';

export class Client extends Entity{
  public id: number = null;
  public decoratorId: number = null;
  public name: string = null;
  public address: string = null;
  public city: string = null;
  public state: string = null;
  public postalCode: string = null;
  public phone: string = null;
  public email: string = null;
  public avatarImg: string = null;
  public avatarTwoChar: string = null;

}

export interface IClient {
  id: number ,
  decoratorId: number ,
  name: string ,
  address: string ,
  city: string ,
  state: string ,
  postalCode: string ,
  phone: string ,
  email: string ,
  avatarImg: string ,
  avatarTwoChar: string,
  account_manager: number,
  salesperson: number,
  salespricegrid: number,
  created_date: string,
} 

export interface IClientResponse {
  success: boolean,
  data: [IClient],
  message: string,
pagination?: Object
  }

  export interface IClientData {
    client_id: number,
    decorator_id: number
}
export interface ICreateClientContact {
  client_id: number,
  first_name: string,
  last_name: string,
  email: string
}
