import { Directive, ElementRef, EventEmitter, Output, NgZone, OnInit  } from '@angular/core';

@Directive({
  selector: '[appGooglePlaces]'
})
export class GooglePlacesDirective {
  @Output() addressChange: EventEmitter<any> = new EventEmitter();
  private autocomplete: any;

  constructor(private elRef: ElementRef, private ngZone: NgZone) {}

  ngOnInit() {
    this.autocomplete = new google.maps.places.Autocomplete(this.elRef.nativeElement, {
      types: ['address']
    });
    
    this.autocomplete.addListener('place_changed', () => {
      this.ngZone.run(() => {
        // Get the place result
        let place: google.maps.places.PlaceResult = this.autocomplete.getPlace();
        // Emit the address object for the selected place
        this.addressChange.emit(place);
      });
    });
    
  }
}
