import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {LoginBaseComponent} from './features/login/login-base/login-base.component';
import {BaseComponent} from './core/base/base.component';
import {AboutStokkupComponent} from './features/about/about-stokkup/about-stokkup.component';
import {PrivacyPolicyComponent} from './features/about/privacy-policy/privacy-policy.component';
import {SupportComponent} from './features/about/support/support.component';
import {TermsOfServiceComponent} from './features/about/terms-of-service/terms-of-service.component';
import {AuthGuardService} from "./core/services/auth/auth-guard.service";
import { BaseNavResolver } from './core/resolver/base-nav.resolver';


const routes: Routes = [
  { path: '', redirectTo: '/catalog', pathMatch: 'full', resolve: {baseResolver: BaseNavResolver}},
  { path: 'login', component: LoginBaseComponent, loadChildren: () => import('./features/login/login.module').then(m => m.LoginModule) },
  { path: 'orders', component: BaseComponent, loadChildren: () => import('./features/orders/orders.module').then(m => m.OrdersModule)},
  { path: 'floor-stock', component: BaseComponent, loadChildren: () => import('./features/floor-stock/floor-stock.module').then(m => m.FloorStockModule)},
  { path: 'catalog', component: BaseComponent, loadChildren: () => import('./features/catalog/catalog.module').then(m => m.CatalogModule) },
  { path: 'dashboard', component: BaseComponent, canActivate: [AuthGuardService], loadChildren: () => import('./features/dashboard/dashboard.module').then(m => m.DashboardModule) },
  { path: 'cart', component: BaseComponent, canActivate: [AuthGuardService], loadChildren: () => import('./features/cart/cart.module').then(m => m.CartModule) },
  { 
    path: 'about', component: BaseComponent, children: [
      { path: '', component: AboutStokkupComponent },
      { path: 'privacy-policy', component: PrivacyPolicyComponent },
      { path: 'support', component: SupportComponent },
      { path: 'terms-of-service', component: TermsOfServiceComponent },
    ]
  },
  {
    path: '**',
    redirectTo: 'dashboard'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
