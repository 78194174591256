import { Component, HostListener, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { SessionService } from "../services/session/session.service";
import { Router } from "@angular/router";
import { onMainContentChange } from '../animations';
import { filter, first, Subject, takeUntil } from 'rxjs';
import { User } from '@core/models/user';
import { AuthService } from '@core/services/auth/auth.service';
import { MdbDropdownDirective } from 'mdb-angular-ui-kit/dropdown';
import { Store, select } from '@ngrx/store';
import * as AuthActions from '@core/state/auth/auth.actions';
import { HelperService } from '../services/api/helper.service';
import { ApiService } from '../services/api/api.service';
import * as CatalogActions from '@core/state/catalog/catalog.actions';
import * as fromCatalog from '@core/state/catalog/catalog.selectors';
import { MongoDbService } from '../services/api/mongodb.service';
import * as fromUser from '@core/state/user/user.selector';
import { SharedService } from '@shared/shared.service';
import { getAllUserClients } from '@core/state';
import { IClient } from '@core/models/client';

@Component({
  selector: 'app-base',
  templateUrl: './base.component.html',
  styleUrls: ['./base.component.scss'],
  animations: [onMainContentChange]
})
export class BaseComponent implements OnInit, OnDestroy {
  onSideNavChange: boolean;
  year = new Date().getFullYear()
  viewport
  destroyed$ = new Subject<void>();
  isOpen: boolean
  activeDecorator
  guestDecorator;
  clientName: string = '';
  user: User;
  authenticated: boolean
  search: string;
  decorator: any;
  screenWidth: any;
  brands: any
  brand: any;
  categories: any
  category: string = '';
  decorations: any
  decorationType: string = '';
  showNewCartInput: boolean = false;
  cartName: string = '';
  selectedItemIndex: number = -1; // Index of the currently selected accordion item
  navigation = [];
  subNavigationCS = [
    { link: '/dashboard', name: 'Dashboard', icon: 'fa-home me-3' },
    { link: '/orders', name: 'Orders', hide: false, icon: 'fa-clipboard-list' },
    { 
      link: '/floor-stock',
      name: 'Floor Stock',
      hide: false,
      icon: 'fa-boxes-packing',
      label: {
        text: 'beta',
        backgroundColor: 'black',
        textColor: 'white'
      }
    },
    { link: '/catalog', name: 'Catalog', hide: true, icon: 'fa-book-open' },
  ]
  subNavigationCatalog = [
    { link: '/catalog', name: 'Catalog', hide: true }
  ]
  clientId: any;
  isAffiliate: any;
  @Input() carts: any
  @ViewChild('dropdown') dropdown!: MdbDropdownDirective;
  clients: IClient[];

  constructor(
    protected session: SessionService,
    protected router: Router,
    protected api: ApiService,
    protected auth: AuthService,
    private store: Store,
    private helper: HelperService,
    private _mongo: MongoDbService,
    private sharedService: SharedService,
  ) {}

  async ngOnInit() {
    this.store.dispatch(getAllUserClients({decoratorId: 100015}))
    this.decorator = this.session.getActiveDecorator();
    this.authenticated = this.auth.isAuthenticated();
    if(this.authenticated) {
      this.store.pipe(select(fromUser.selectUserIsAffiliate), takeUntil(this.destroyed$)).subscribe((user) => {
        this.isAffiliate = user
    });
    }
    if(this.authenticated && this.isAffiliate ==  0) {
      this.clientId = this.session.getActiveClient().id
      this.store.dispatch(CatalogActions.getCarts({ clientId: this.clientId }));
    }
    this.store.select(fromUser.selectClients).pipe(filter(resp => !!resp), first()).subscribe((resp) => this.clients = resp)
    this.activeDecorator = this.decorator ? this.decorator.id : null
    this.guestDecorator = this.session.getGuestType();

    this.getNavigation();

    this.user = this.session.getUser()
    this.clientName = this.session.getActiveClient() ? this.session.getActiveClient().name : this.clientName;
    this.screenWidth = window.innerWidth;
    if (this.router.url === '/') {
      let user = this.session.getUser();
      let client = this.session.getActiveClient();
      let decorator = this.session.getActiveDecorator();
      if (user && decorator && client) {
        this.router.navigate(['/orders']);
      }
      else if (user && decorator) {
        this.router.navigate(['/choose/client']);
      }
      else if (user) {
        this.router.navigate(['/choose/decorator']);
      }
      else {
        this.router.navigate(['/login']);
      }
    }

    this.store.pipe(select(fromCatalog.selectCarts), takeUntil(this.destroyed$)).subscribe((carts => {
      this.carts = carts
    }));

    this.getBrands()
    this.getCategories()
    this.getDecoration()
  }

  getNavigation() {
    this.navigation = [
      { link: '/dashboard',
        name: 'Dashboard',
        show: (this.activeDecorator && this.authenticated)
      },
      {
        link: '/orders',
        name: 'Orders',
        show: this.authenticated
      },
      {
        link: '/floor-stock',
        name: 'Floor Stock',
        show: (this.activeDecorator && this.authenticated),
        label: {
          text: 'beta',
          backgroundColor: 'black',
          textColor: 'white'
        }
      },
      {
        link: '/catalog',
        name: 'Catalog',
        show: !this.authenticated || (this.activeDecorator && this.authenticated)
      }
    ]
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.screenWidth = window.innerWidth;
  }

  toggleAccordion(index: number): void {
    if (this.selectedItemIndex === index) {
      this.selectedItemIndex = -1; // Collapse if already open
    } else {
      this.selectedItemIndex = index; // Expand the selected item
    }
  }

  toggleNewCartInput(event: Event) {
    event.stopPropagation(); // Prevent the click event from propagating
    this.showNewCartInput = !this.showNewCartInput;
    this.cartName = ''; // Clear input field when toggling
  }

  /**
 * Asynchronous function to add a new cart.
 */
  async addNewCart() {
    const cart = await this._mongo.cartTemplate()

    if (this.cartName) {
      cart.cartName = this.cartName;
      this.toggleNewCartInput(event); // Hide input field after adding
    } else {
      this.openAlert('Please input a Cart name.');
      return
    }

    this.store.dispatch(CatalogActions.addCart({
      cart: cart
    }));

    this.store.pipe(select(fromCatalog.selectCarts), takeUntil(this.destroyed$)).subscribe((carts => {
      this.carts = carts
    }));
  }


  /**
 * Function to delete a cart item.
 *
 * @param {Object} cart - The cart object to be deleted.
 */
  deleteCart(cart) {
    if (confirm(`Are you sure you want to delete ${cart.cartName}? This will remove the cart permanently.`)) {
      const deleteCart = { ...cart, _id: undefined, version: this.helper.getTimestamp(), active: false };

      this.store.dispatch(CatalogActions.addCart({
        cart: deleteCart
      }));
      this.store.pipe(select(fromCatalog.selectCarts)).pipe(takeUntil(this.destroyed$)).subscribe((carts => {
        this.carts = carts
      }));
    }
  }

  setClasses(type) {

    if (type == 'text') {
      return this.activeDecorator === 100015 || this.activeDecorator === null ? 'search-text-light' : 'search-text-dark';
    }
    if (type == 'search') {
      return this.activeDecorator === 100015 || this.activeDecorator === null ? 'bg-light-input' : 'bg-dark-input';
    }
  }

  logout() {
    this.store.dispatch(AuthActions.logoutUser({ decorator: this.activeDecorator }));
  }

  /**
 * Get list of brands from MongoDB
 */
  protected getBrands() {
    this._mongo.getBrands().pipe(takeUntil(this.destroyed$)).subscribe(
      (data: any) => {
        this.brands = data; // Assign the data to your brands array

        this.store.dispatch(CatalogActions.updateCatalogBrandsNav(
          {
            brands: { 'Brands': this.brands }
          }
        ));
      },
      error => {
        console.error('Error fetching brands:', error);
      }
    );
  }

  /**
 * Mongo endpoint to get the categories for all products in the catalog
 */
  protected getCategories() {
    this._mongo.getCategories().pipe(takeUntil(this.destroyed$)).subscribe(
      (data: any) => {
        this.categories = data; // Assign the data to your categories array

        this.store.dispatch(CatalogActions.updateCatalogCategoriesNav(
          {
            categories: { 'Categories': this.categories }
          }
        ));
      },
      error => {
        console.error('Error fetching categories:', error);
      }
    );
  }

  protected getDecoration() {
    this.decorations = [
      'Sublimation',
      'Branded Merchandise',
      'Embroidery',
      'Dye & Wash',
      'Woven / Patch',
      'Laser',
      'On Demand',
      'Transfer',
      'Print'
    ]
  }

  getLogo() {
    return 'https://img.stokkup.com/app/cs-circle-logo.png';
  }

  filterNavbar(key: string, item: string) {
    this.search = !key ? '' : this.search
    if (!key && !item) {
      this.router.navigate(['catalog/'])
    } else {
      let url = `catalog/all`
      this.router.navigate([url], { queryParams: { [key]: item } })
    }
  }

  openAlert(prompt) {
    this.sharedService.showNotification({ data: { title: prompt, class: '' }, autohide: true });
  }

  ngOnDestroy() {
    this.destroyed$.next()
  }
}
