import { Injectable } from '@angular/core';
import { Router, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Observable, first, of, tap } from 'rxjs';
import { select,Store } from '@ngrx/store';
import * as ClientActions from '@core/state/client/client.actions';
import * as fromClient from '@core/state/client/client.selectors';

@Injectable({
  providedIn: 'root'
})
export class ClientResolver  {
  constructor(private store: Store, private router: Router){
    this.store.dispatch(ClientActions.getClient());
  }
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    return this.store.pipe(
      select(fromClient.selectClient),
      tap((c) => {
        if(c === null){
          this.router.navigate(['/login']);
        }
      }),
      first()
    );
  }
}
