<div class="accordion-content overflow-scroll" [hidden]="!isOpen">
    <ng-container *ngFor="let design of carts.designs">
        <div class="cart-product mt-2">
            <div class="row">
                <div class="col-md-3  d-flex align-items-center justify-content-center">
                    <img *ngIf="design?.positions[0]" [src]="design?.positions[0]?.mockupImage" alt="{{ design.designName }}" class="img-fluid" loading="lazy">
                </div>
                <div class="col-md-9">
                    <div class="cart-product-name">{{ design.designName }}</div>
                    <div class="cart-variant">
                        <div class="cart-variant-item">
                            <strong>Products:</strong> {{ calculateProductQty(design.products) }}
                        </div>
                        <div class="cart-variant-item">
                            <strong>Quantity:</strong> {{ calculateSizeQty(design.products) }}
                        </div>
                    </div>
                </div>
     
            </div>
        </div>
    </ng-container>
    <div class="mt-2">
        <a class="cart-cancel me-2" [routerLink]="'/cart/' + carts.cartId">View Cart</a>
        <button class="cart-add" [routerLink]="'/cart/' + carts.cartId + '/checkout'">Checkout</button>
    </div>
</div>