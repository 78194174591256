import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import {Subscription} from "rxjs/index";
import {LoaderState} from "../services/api/loader-state";
import {HttpLoaderService} from "../services/api/http-loader.service";

@Component({
  selector: 'app-http-loader',
  templateUrl: './http-loader.component.html',
  styleUrls: ['./http-loader.component.scss']
})
export class HttpLoaderComponent implements OnInit {
  public show = false;
  private subscription: Subscription = null;

  constructor(
    private loaderService: HttpLoaderService,
    private cdRef: ChangeDetectorRef
    ) { }

  ngOnInit() {
    this.subscription = this.loaderService.loaderState
      .subscribe((state: LoaderState) => {
        this.show = state.show;
        this.cdRef.detectChanges(); // Trigger change detection after updating show
      });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
