import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ErrorsService } from '@core/services/errors/errors.service';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent {
  constructor(public errorsService: ErrorsService, private router: Router, private route: ActivatedRoute) {}

  goOut(routerLink: string, routerParams?: Object) {
    this.router.navigate([routerLink], {queryParams: routerParams})
    setTimeout(() => {
      this.errorsService.hideError()
      this.errorsService.setErrorState(undefined)
    }, 500)
  }
}
