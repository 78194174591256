import { createReducer, on } from "@ngrx/store";
import { authLoginRequestFailure, authLoginRequestSuccess, getAuthUserSuccess, getAuthUserFailure, logoutUserSuccess, logoutUserFailure } from "./auth.actions";

export interface State {
    token: string,
    authLoginError?: string,
    authenticated?: boolean
}

export const initialState: State = {
    token: null,
    authenticated: false,
}

const _authReducer = createReducer(
    initialState, 
    on(authLoginRequestSuccess, (state, {loginSuccessResponse}) => {
        return {
            ...state,
            token: loginSuccessResponse.data.token,
            authenticated: true
        }
    }),
    on(authLoginRequestFailure, (state, {error}) => {
        return {
            ...state,
            authLoginError: error,
            token: null,
            authenticated: false,
        }
    }),
    on(getAuthUserSuccess, (state, {user}) => {
        return {
            ...state,
            authenticated: true,
            token: user.token
        }
    }),
     on(getAuthUserFailure, (state, {error}) => {
        return {
            ...state,
            authenticated: false,
            token: null,
            authLoginError: error
        }
    }),
    on(logoutUserSuccess, (state, {status}) => {
        return {
            ...state,
            authenticated: false,
            token: null
        }
    } ),
    on(logoutUserFailure, (state, {error}) => {
        return {
            ...state,
            authenticated: null,
            token: null,
            authLoginError: error
        }
    } )
    
)

export function authReducer(state, action){
    return _authReducer(state, action);
}
