import { Injectable } from "@angular/core";

@Injectable({providedIn: 'root'})

export class HelperService {
    constructor(){}

    /**
     * Helper Method that will check the object value and see if they are null, empty or undefined
     * If found to be one of the above criteria then it will return false
     * @param obj 
     * @returns boolean
     */
     public checkObjectIsNullish(obj){
         const isNullish = Object.values(obj).every(value => {
             if(value == null || value === undefined || value === ''){
                 return false;
             }
             return true
         })
         
         return isNullish

     }

     public titleCase(str) {
        return str.replace(/\w\S*/g, (t) => { return t.charAt(0).toUpperCase() + t.substr(1).toLowerCase() });
      }

      public lowerCase(str){
          if(str) {
            return str.toLowerCase();
          } else {
              return str
          }
      }

      public objectToSingleArray(obj){
        let result =[]
        obj.map(data => {
            return result.push(data['name'].toString())
        })
        
        return result
      }

    /**
     * Method used to format Date into YYYY-MM-DD when the value is i.e Thu Jun 09 2011 00:00:00 GMT+0530 (India Standard Time)
     * @param value date string
     * @returns date
     */
    public formatDate(value){
      let ndate = new Date(value),
          mnth = ("0" + (ndate.getMonth() + 1)).slice(-2),
          day = ("0" + ndate.getDate()).slice(-2);
      return [ndate.getFullYear(), mnth, day].join("-");       
    }

    getCurrentDate(date){
        // let c_date = new Date();
        
        // current day - adjust 0 before single digit date
        let day = this.getDay(date);
        // current month
        let month = this.getMonth(date);
        // current year
        let year = this.getYear(date);

        return (year + "-" + month + "-" + day);
    }

      getYear(date){
          // current year
          return date.getFullYear();
      }
      getMonth(date){
          // current month
          return ("0" + (date.getMonth() + 1)).slice(-2);
      }
      getDay(date){
          // current day
          return ("0" + date.getDate()).slice(-2);
      }

      generateUUID() {
        // built-in Javascript UUID generator. no 3rd party package needed
        return crypto.randomUUID();
      }

      getTimestamp() {
        return Math.floor(new Date().getTime() / 1000)
      }

      formatDateForCart(inputDate) {
        const date = new Date(inputDate);
  
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-based, so add 1
        const day = date.getDate().toString().padStart(2, '0');
        const year = date.getFullYear().toString();
        return `${month}/${day}/${year}`;
      }

      getProductImages(item) {
        if (!item || !item.images) {
            console.error('getProductImages called with invalid item:', item);
            return [];
        }
        const productImages = Object.values(item.images);
    
        productImages.forEach((option, index, array) => {
            if (typeof option === 'string' && option.includes('ssactivewear')) {
                array[index] = option.replace('/Images/Style', ''); // Remove "/Images/Style"
            }
        });
    
        return productImages;
      }
      
}