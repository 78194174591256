import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { CartBaseComponent } from "./cart-base/cart-base.component";
import { CartCheckoutComponent } from "./cart-checkout/cart-checkout.component";
import { AuthResolver } from "@core/resolver/auth.resolver";
import { UserDecoratorResolver } from "@core/resolver/user-decorator.resolver";
import { UserResolver } from "@core/resolver/user.resolver";
import { ClientResolver } from "@core/resolver/client.resolver";

/**
 * Check if the user is logged in
 * @returns boolean (true - logged in | false - not logged in)
 */
function userIsLoggedIn(): boolean {
    // Retrieve the authentication token from localStorage
    const authToken = localStorage.getItem('_auth_token');
  
    // Check if the authentication token exists and has a non-empty value
    // If true, the user is considered logged in; otherwise, they are not logged in
    return authToken !== null && authToken.trim() !== '' ? true : false;
  }
  
  /**
   * Determine the resolvers based on whether the user is logged in
   * @type {{
   *   auth?: AuthResolver,          // Resolver for authentication
   *   userDecorator?: UserDecoratorResolver,  // Resolver for user decoration
   *   user?: UserResolver,           // Resolver for user data
   *   client?: ClientResolver        // Resolver for client data
   * }}
   */
  const isUserLoggedInResolvers = userIsLoggedIn()
    ? {
        auth: AuthResolver,
        userDecorator: UserDecoratorResolver,
        user: UserResolver,
        client: ClientResolver,
      }
    : {};
  
const routes: Routes = [
    { path: '', resolve: isUserLoggedInResolvers, component: CartBaseComponent },
    { path: ':id', resolve: isUserLoggedInResolvers, component: CartBaseComponent },
    { path: ':id/checkout', resolve: isUserLoggedInResolvers, component: CartCheckoutComponent },
    { path: 'checkout', resolve: isUserLoggedInResolvers, component: CartCheckoutComponent },
    { path: 'confirmation/:orderId', resolve: isUserLoggedInResolvers, component: CartCheckoutComponent },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class CartRoutingModule { }