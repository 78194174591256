import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Cart, CartDesign, CartVariant } from '@core/models/catalog';
import { HelperService } from '@core/services/api/helper.service';
import { Store } from '@ngrx/store';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';
import * as CatalogActions from '@core/state/catalog/catalog.actions';

@Component({
  selector: 'app-cart-variant-table-modal',
  templateUrl: './cart-variant-table-modal.component.html',
  styleUrls: ['./cart-variant-table-modal.component.scss']
})
export class CartVariantTableModalComponent implements OnInit {
  @Input() cart: Cart;
  @Input() design: CartDesign;
  @Output() closeModalEvent = new EventEmitter<void>();
  @Output() saveChangesEvent = new EventEmitter<number[]>();
  colorSizes: any;
  variantSizes: any[] = [];
  inputChanges: any[] = [];
  updatedVariants: any[];

  constructor(
    public modalRef: MdbModalRef<CartVariantTableModalComponent>,
    private store: Store,
    private helper: HelperService,

  ) { }

  ngOnInit(): void {
    this.updatedVariants = [...this.design.products.variants]
    this.colorSizes = this.groupVariantsByColor(this.design.products.variants)
  }

  // Function to group variants by color
  groupVariantsByColor(variants: CartVariant[]): any[] {
    const colorGroups: {color: string, variants: CartVariant[]}[] = [];

    variants.forEach((variant) => {
      const existingGroup = colorGroups.find((group) => group.color === variant.color);

      if (existingGroup) {
        if(!existingGroup.variants.includes(variant)) {
          existingGroup.variants.push(variant);
        }
      } else {
        colorGroups.push({
          color: variant.color,
          variants: [variant],
        });
      }
    });

    return colorGroups;
  }

  onInputChanged(event: Event, variant: any) {
    const updatedVariant = { ...variant }
    const updatedQty = (event.target as HTMLInputElement).valueAsNumber;
    updatedVariant.qty = updatedQty;
    const replaceVariants = this.updatedVariants.map((v) => {
      if (v.size === variant.size) {
        // Clone the original variant and update the qty
        return { ...v, qty: updatedQty };
      }
      return v;
    });

    this.updatedVariants = replaceVariants
  }

  closeModal() {
    this.modalRef.close()
  }

  // Define a function to update variants in a product
  updateVariants(product, updatedVariants) {
    return { ...product, variants: updatedVariants };
  }

  saveChanges() {
    // Logic where it will replace variants with this.updatedVariants in the correct cart design & product & variants
    const updatedCart = {
      ...this.cart,
      _id: undefined,
      version: this.helper.getTimestamp(),
      designs: this.cart.designs.map((design) => {
        if (design._id === this.design._id) {
          return {
            ...design,
            products: this.updateVariants(design.products, this.updatedVariants)
          };
        }
        return design; // Return the original design for others
      }),
    };

    this.store.dispatch(CatalogActions.addCart({
      cart: updatedCart,
    }));

    this.closeModal();
  }
}
