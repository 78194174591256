import { Action, createAction, props } from "@ngrx/store";
import { IUser } from "src/app/core/models/user";
import { IClient, IClientData, IClientResponse } from "../../models/client";
import { IDecorator, IDecoratorResponse } from "../../models/decorator";

// Get User Profile
export const getUserInfo = createAction('[User] Get User Profile')
export const getUserInfoSuccess = createAction(
    '[User] Get User Profile Success',
    props<{ userResponse: IUser }>()
)
export const getUserInfoFailure = createAction(
    '[User] Get User Profile Failure',
    props<{ error: string }>()
)

// Get All User Decorators
export const getAllUserDecorators = createAction(
    '[User] Get All User Decorators'
)
export const getAllUserDecoratorsSuccess = createAction(
    '[User] Get All User Decorators Success',
    props<{ userDecorators: IDecoratorResponse }>()
)
export const getAllUserDecoratorsFailure = createAction(
    '[User] Get All User Decorators Failure',
    props<{ error: string }>()
)

// Get All Affiliate Decorators 
export const getAllAffiliateDecorators = createAction(
    '[User] Get All Affiliate Decorators'
)
export const getAllAffiliateDecoratorsSuccess = createAction(
    '[User] Get All Affiliate Decorators Success',
    props<{ affiliateDecorators: IDecoratorResponse }>()
)
export const getAllAffiliateDecoratorsFailure = createAction(
    '[User] Get All Affiliate Decorators Failure',
    props<{ error: string }>()
)

// Get All User Client
export const getAllUserClients = createAction(
    '[User] Get All User Clients',
    props<{ decoratorId: number}>()
)
export const getAllUserClientsSuccess = createAction(
    '[User] Get All User Clients Success',
    props<{ userClients: IClientResponse }>()
)
export const getAllUserClientsFailure = createAction(
    '[User] Get All User Clients Failure',
    props<{ error: string }>()
)

// Set User Profile
export const setUserInfo = createAction('[User] Get User Profile')
export const setUserInfoSucccess = createAction(
    '[User] Get User Profile Success',
    props<{ userResponse: IUser}>()
)
export const setUserInfoFailure = createAction(
    '[User] Get User Profile Failure',
    props<{ error: string}>()
)

// Get User Decorator
export const getUserDecorator = createAction(
    '[User] Get User Decorator'
)
export const getUserDecoratorSuccess = createAction(
    '[User] Get User Decorator Success',
    props<{ userDecorator: IDecorator }>()
)
export const getUserDecoratorFailure = createAction(
    '[User] Get User Decorator Failure',
    props<{ error: string }>()
)

// Set User Decorator
export const setUserDecorator = createAction(
    '[User] Set User Decorator',
    props<{ decorator: IDecorator}>()
)
export const setUserDecoratorSuccess = createAction(
    '[User] Set User Decorator Success',
    props<{ userDecorator: IDecorator }>()
)
export const setUserDecoratorFailure = createAction(
    '[User] Set User Decorator Failure',
    props<{ error: string }>()
)

// Get User Client
export const getUserClient = createAction(
    '[User] Get User Client'
)
export const getUserClientSuccess = createAction(
    '[User] Get User Client Success',
    props<{ userClient: IClient }>()
)
export const getUserClientFailure = createAction(
    '[User] Get User Client Failure',
    props<{ error: string }>()
)

// Set User Client
export const setUserClient = createAction(
    '[User] Set User Client',
    props<{ client: IClient}>()
)
export const setUserClientSuccess = createAction(
    '[User] Set User Client Success',
    props<{ userClient: IClient }>()
)
export const setUserClientFailure = createAction(
    '[User] Set User Client Failure',
    props<{ error: string }>()
)

// Set User Client Context
export const setUserClientContext = createAction(
    '[User] Set User Client Context',
    props<{ client: IClient, decorator_id: number}>()
)
export const setUserClientContextSuccess = createAction(
    '[User] Set User Client Context Success',
    props<{ userClientContext: IClientData }>()
)
export const setUserClientContextFailure = createAction(
    '[User] Set User Client Context Failure',
    props<{ error: string }>()
)