import { Injectable } from '@angular/core';
import { WindowReferenceService } from './window-reference.service';

@Injectable({
  providedIn: 'root'
})
export class DataLayerService {

  private window;

  constructor (private _windowRef: WindowReferenceService) {
       this.window = _windowRef.nativeWindow; // intialise the window to what we get from our window service

  }

  private pingHome(obj) {
    if(obj)  this.window.dataLayer.push(obj);
  }

  // add new method
  conversionShareASales(event, orderId, subtotal){
    const hit = {
      event: event,
      orderId: orderId,
      subtotal: subtotal
    }
    this.pingHome(hit);
  }

  logPageView(url) {
    const hit = {
      event: 'content-view',
      pageName: url
    };
    this.pingHome(hit);  
  }

  logProductView(brand, style, data){
    const hit = {
      event: 'product-view',
      brand: brand,
      style: style,
      product: data
    }
    this.pingHome(hit);  
  }

  logEvent(event,category,action,label, data, viewport = 'desktop') {
    const hit = {
        event:event,
        category:category,
        action:action,
        label: label,
        product: data,
        viewport: viewport
    }
    this.pingHome(hit);
  }

  logOrderConversionEvent(event,category,action,label, data, viewport = 'desktop') {
    const hit = {
        event:event,
        category:category,
        action:action,
        label: label,
        order: data,
        viewport: viewport
    }
    this.pingHome(hit);
  }

  logCustomDimensionTest(value) {
    const hit = {
        event:'custom-dimension',
        value:value
    }
    this.pingHome(hit);
  }
}
