import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from "../../../../environments/environment";
import { Observable, delay, map } from 'rxjs';
import { Base64 } from 'js-base64';

@Injectable({
  providedIn: 'root'
})
export class EasypostService {
  constructor(
    protected http: HttpClient,
  ) { }

   verifyAddress(address) {
    const url = `${environment.apiMicroservice}marketplace/carts/verifyAddress`

      return this.http.post(url, address).pipe(
      map((data: any) => {
        return data;
      }),
      delay(300),
    );
  }

  getShippingRates(shipment) {
    const url = `${environment.apiMicroservice}marketplace/carts/getShippingRates`

    return this.http.post(url, shipment).pipe(
      map((data: any) => {
        return data;
      }),
      delay(300),
    );
  }

  formatShippingRateDTO(toAddress) {
    let rateDTO = {};

    rateDTO = {
        to_address: toAddress,
        from_address: {
          name: "Culture Studio",
          street1: "1151 W 40th St.",
          street2: "",
          city: "Chicago",
          state: "IL",
          zip: "60609",
          country: "US",
          phone: "3122438304",
          email: "support@easypost.com"
        },
        parcel: {
          length: 20.2,
          width: 10.9,
          height: 5,
          weight: 65
        },
        carrier_accounts: ["ca_60525909c2aa48429c2503f1faa733e6"]
      
    }

    return rateDTO
    
  }
}
