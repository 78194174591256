import {Injectable} from "@angular/core";
import { Observable, share } from "rxjs";
import { ApiService } from "../../core/services/api/api.service";


@Injectable({ providedIn: 'root' })

export class DashboardService {

    constructor(
        protected apiService: ApiService
    ){}

    public getDashboardMetrics(clientId, type, body) : Observable<Object> {
        let metricsData$ = this.apiService.postV2(`api/v2/order/platform/metrics/${clientId}/${type}`, body).pipe(share())

        return metricsData$
    }

    public getDashboardShippingMapMetrics(clientId) : Observable<Object>{
        let map$ = this.apiService.getApiv2(`api/v2/order/platform/metrics/${clientId}/shipping`).pipe(share());

        return map$;
    }

    getQuarterMonths(){
        const quarterOne = ['January', 'February', 'March'];
        const quarterTwo = ['April', 'May', 'June'];
        const quarterThree = ['July', 'August', 'September'];
        const quarterFour = ['October', 'November', 'Decemeber'];

        return {
            q_1: quarterOne, q_2: quarterTwo, q_3: quarterThree, q_4: quarterFour
        }
    }

    getQuarter(date = new Date()){
        return Math.floor(date.getMonth() / 3 + 1);
    }
}