import { Injectable } from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable, take } from 'rxjs';
import * as AuthActions from '@core/state/auth/auth.actions';
import * as fromAuth from '@core/state/auth/auth.selectors';

@Injectable({
  providedIn: 'root'
})
export class AuthResolver  {
  constructor(
    private store: Store
  ){
    this.store.dispatch( AuthActions.getAuthUser());
  }
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
   
   
    return this.store.pipe(select(fromAuth.selectIsLoggedIn)).pipe(take(1))

  }
}
