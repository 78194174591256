import { Component } from '@angular/core';
import {AuthService} from "./core/services/auth/auth.service";
import { ErrorsService } from './core/services/errors/errors.service';
import { GlobalLoaderService } from '@core/services/global-loader.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  public title = 'cs-platform';
  public constructor(
    auth: AuthService,
    public errorService: ErrorsService,
    public globalLoaderService: GlobalLoaderService,
  ){
    auth.attemptPreAuth();
  }
}
