import { createAction, props } from "@ngrx/store";
import { IDashboardMapResponse, IDashboardMetricsResponse, IDashboardPayload } from "../../models/dashboard";


export const getDashboardMetrics = createAction(
    '[Dashboard] Get Dashboard Metrics ',
    props<{ payload: IDashboardPayload }>()

)
export const getDashboardMetricsSuccess = createAction(
    '[Dashboard] Get Dashboard Metrics Success',
    props<{ metricsResponse: IDashboardMetricsResponse }>()
)
export const getDashboardMetricsFailure = createAction(
    '[Dashboard] Get Dashboard Metrics Failure',
    props<{ error: string }>()
)

export const getDashboardMetricsEstimate = createAction(
    '[Dashboard] Get Dashboard Metrics Estimate ',
    props<{ payload: IDashboardPayload }>()

)
export const getDashboardMetricsEstimateSuccess = createAction(
    '[Dashboard] Get Dashboard Metrics Estimate Success',
    props<{ metricsResponse: IDashboardMetricsResponse }>()
)
export const getDashboardMetricsEstimateFailure = createAction(
    '[Dashboard] Get Dashboard Metrics Estimate Failure',
    props<{ error: string }>()
)

export const getDashboardMetricsProduction = createAction(
    '[Dashboard] Get Dashboard Metrics Production',
    props<{ payload: IDashboardPayload }>()
)
export const getDashboardMetricsProductionSuccess = createAction(
    '[Dashboard] Get Dashboard Metrics Production Success',
    props<{ metricsResponse: IDashboardMetricsResponse }>()
)
export const getDashboardMetricsProductionFailure = createAction(
    '[Dashboard] Get Dashboard Metrics Production Failure',
    props<{ error: string }>()
)

export const getDashboardMetricsClosed = createAction(
    '[Dashboard] Get Dashboard Metrics Closed',
    props<{ payload: IDashboardPayload }>()

)
export const getDashboardMetricsClosedSuccess = createAction(
    '[Dashboard] Get Dashboard Metrics Closed Success',
    props<{ metricsResponse: IDashboardMetricsResponse }>()
)
export const getDashboardMetricsClosedFailure = createAction(
    '[Dashboard] Get Dashboard Metrics Closed Failure',
    props<{ error: string }>()
)

export const getDashboardShippingMap = createAction(
    '[Dashboard] Dashboard Shipping Map',
    props<{ clientId: number}>()
)
export const getDashboardShippingMapSuccess = createAction(
    '[Dashboard] Dashboard Shipping Map Success',
    props<{ shippingMapResponse: [IDashboardMapResponse]}>()
)
export const getDashboardShippingMapFailure = createAction(
    '[Dashboard] Dashboard Shipping Map Failure',
    props<{ error: string}>()
)
