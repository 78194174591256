<section class="about-card card">
  <h1>Privacy Policy</h1>
  <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
      style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Privacy
      Statement</span></p>
  <p><br></p>
  <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
      style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Updated
      -- Feb 14, 2022</span></p>
  <p><br></p>
  <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
      style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Scroll
      down for a full Statement. Immediately below is a Summary of the information we collect and the ways in which we
      use and/or disclose it. Please read our full policy BELOW THE SUMMARY .</span></p>
  <p><br></p>
  <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
      style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
  </p>
  <p><br></p>
  <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
      style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Summary
      of Privacy Statement</span></p>
  <p><br></p>
  <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
      style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Our
      website is directed to general audiences over 18 years of age and who are capable of entering into binding
      obligations.</span></p>
  <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
      style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">We
      collect personal information you provide to us. By personal information we mean information that is identifiable
      to you or to a particular device, such as name, email address, and home address, or that is accessible to us
      because you have elected to connect your Stokkup account to your social media account.</span></p>
  <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
      style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">We
      may collect information automatically such as log-in details.</span></p>
  <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
      style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">We
      may also collect navigational information. Such information is &ldquo;personal information&rdquo; if identifiable
      to you or a particular device.</span></p>
  <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
      style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">We
      generally use your personal information to process and fulfill orders, to provide information to you, and to
      improve our services.</span></p>
  <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
      style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">We
      may use cookies, for example, to remember your preferences and to facilitate your logging in to the site.</span>
  </p>
  <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
      style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">We
      do not share information with third-party advertisers.</span></p>
  <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
      style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">We
      may disclose information to third parties, such as our parent company, subsidiaries and affiliates, service
      providers, social media accounts, when under legal compulsion, if we undergo a reorganization or sale, or as
      otherwise allowed by law.</span></p>
  <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
      style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">We
      attempt to implement reasonable security procedures to protect your information, and we try to contract with
      vendors who do the same.</span></p>
  <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
      style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">We
      may link to third party sites.</span></p>
  <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
      style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">We
      keep your personal information for as long as we believe we need it, and for as long as the law allows us to keep
      it.</span></p>
  <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
      style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">You
      can access and make changes to your personal information under certain circumstances.</span></p>
  <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
      style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Certain
      rights are specifically applicable to California residents.</span></p>
  <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
      style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">How
      we notify you of changes to the Privacy Statement.</span></p>
  <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
      style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">You
      may contact us at info@stokkup.com.</span></p>
  <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;">
    <br><span
      style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Full
      Privacy Statement</span></p>
  <p><br></p>
  <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
      style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">This
      Privacy Statement of Stokkup (&ldquo;Stokkup,&rdquo; &ldquo;we,&rdquo; &ldquo;us,&rdquo; or &ldquo;our&rdquo;)
      describes its policy for treatment of personal information (as defined below) located at www.stokkup.com
      (individually and collectively, the &ldquo;Site&rdquo;). This Privacy Statement applies only to the Site, and it
      applies to the Site regardless of the platform on which it is used. For clarity, the Site includes all sites that
      are located on sub-domains or subdivisions of www.stokkup.com, and the Privacy Statement currently applies to all
      such sub-domains or subdivisions. &ldquo;You&rdquo; in this Privacy Statement means you personally. If you visit
      other sites operated by Stokkup or other sites linked to the Site, the privacy policy for the Site will not
      control the practices for collecting and using personal information on those other sites. Additionally, this
      Privacy Statement does not apply to Stokkup&rsquo;s practices for collecting and using personal information
      offline or from sites or apps other than the Site (even if that information is identical to the information
      gathered on the Site).</span></p>
  <p><br></p>
  <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
      style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Note
      to residents of Canada: Personal information you provide in connection with your use of the Site may be processed
      and/or stored by Stokkup, its parent, affiliates, corporate partners, or third-party service providers in the
      United States. Any such processing and/or storage will take place in accordance with the terms of this Privacy
      Statement.</span></p>
  <p><br></p>
  <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
      style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">What
      is &ldquo;personal information&rdquo; and what personal information do we collect?</span></p>
  <p><br></p>
  <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
      style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">By
      &ldquo;personal information,&rdquo; we mean information that is reasonably identifiable to you or to a particular
      device.</span></p>
  <p><br></p>
  <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
      style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Personal
      Information We may Collect . Personal information that we collect includes information that you provide
      voluntarily. Personal information requested might include, for example, your name, e-mail address, home address,
      telephone number or, where identifiable to you, other information such as your zip code. NOTE: If you elect to
      make purchases on the Site, you may be required to enter your credit card information. We do not collect sensitive
      credit card information on the Site that may be processed in connection with your purchases in connection with the
      Site. We outsource processing of credit card transactions to vendors that we understand to be compliant with
      appropriate Payment Card Industry standards for security. Additionally, you may have the option of connecting your
      account to social media sites such as Facebook, Twitter, or Google+. Please note that if you make such an
      election, we will be entitled to collect information from the connected social networking website, even if you
      elect not to provide information to us yourself, and we will have access to the social media site information that
      is connected through such permission. That connected information, to the extent we collect it, will constitute
      personal information to the extent identifiable to you (alone or in combination with other information).</span>
  </p>
  <p><br></p>
  <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
      style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Navigational
      Information . When you visit the Site, we may capture certain information automatically (even if you do not
      voluntarily provide us with personal information, or in addition to personal information that you do provide).
      This information may include your Internet protocol address, ISP, location-based information, browser type, time
      and date of access and pages accessed, linking information, click stream, page visits, page views, and website log
      files. If such navigational information is reasonably identifiable to you or is reasonably identifiable to a
      particular device, it will be deemed personal information for purposes of this Privacy Statement. We reserve the
      right to match non-personal navigational information with other information that we have collected from you. If
      that other information renders such navigational information reasonably identifiable to you or a particular
      device, then by definition it will become personal information. Navigational information that is deemed personal
      information under this paragraph will be treated as such pursuant to this Privacy Statement.</span></p>
  <p><br></p>
  <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
      style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Other
      Information You Voluntarily Provide : You may choose to contact us and the information you disclose may constitute
      personal information.</span></p>
  <p><br></p>
  <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
      style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Children
      : Please note the Site is intended for general audiences over eighteen (18) years of age. For the avoidance of
      doubt, the Site is not directed to children younger than 13 years of age. We do not knowingly collect personal
      information from children under the age of 13. (Please review our Terms of Use).</span></p>
  <p><br></p>
  <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
      style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Suggestions
      and Ideas. Any suggestions or similar communications relating to Stokkup&rsquo;s business or the Site how they
      might be changed, enhanced or improved are not treated as private or confidential under this Privacy Statement. We
      will, however, at all times use reasonable efforts to de-identify you personally with the suggestion.</span></p>
  <p><br></p>
  <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
      style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">How
      does Stokkup use the information it collects?</span></p>
  <p><br></p>
  <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
      style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">We
      generally use personal information collected on the Site for the following purposes:</span></p>
  <p><br></p>
  <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
      style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">to
      process and fulfill orders placed on the Site</span></p>
  <p><br></p>
  <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
      style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">to
      respond to inquiries from users and follow up on deliveries of purchases or services</span></p>
  <p><br></p>
  <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
      style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">to
      advise you of opportunities and promotions available on our Site or elsewhere, including product-specific changes
      and updates</span></p>
  <p><br></p>
  <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
      style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">to
      distribute e-newsletters and other informational and/or promotional materials</span></p>
  <p><br></p>
  <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
      style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">to
      provide information about the proper use or misuse of the Site</span></p>
  <p><br></p>
  <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
      style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">to
      process technical or administrative issues or to communicate regarding those issues</span></p>
  <p><br></p>
  <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
      style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">to
      improve, expand, or otherwise adjust our services.</span></p>
  <p><br></p>
  <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
      style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">See
      also circumstances under which we may share or transfer personal information, discussed below .</span></p>
  <p><br></p>
  <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
      style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Does
      the Site use cookies ?</span></p>
  <p><br></p>
  <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
      style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Stokkup
      may use &ldquo;cookies.&rdquo; A cookie is a small text file that a website places on your computer&rsquo;s hard
      drive that allows the website&rsquo;s server to keep track of and facilitate certain activities on the site.
      Cookies help provide information to us about how users are using the Site. Cookies also allow us to remember you
      and your preferences. Among other purposes, we may use cookies to make it easier for you as a user to log in or
      make purchases, or to make visits to the Site otherwise more efficient. We use information that we obtain by
      cookies, to the extent it is or becomes personal information, in accordance with the terms of this policy. You may
      be able to set your browser to accept all cookies, block certain cookies, require your consent before a cookie is
      placed, or block all cookies. Please consult the &quot;Help&quot; section of your browser for more information on
      cookies generally.</span></p>
  <p><br></p>
  <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
      style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Please
      note that if you reject all cookies, you may not be able to take advantage of certain Site offerings.</span></p>
  <p><br></p>
  <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
      style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Please
      note that we may collect information using a third-party web analytics tool such as Google Analytics
      (http://www.google.com/analytics/index.html) that aggregates data so that it is not identifiable to any particular
      individual or device. As of the Effective Date of this Privacy Statement, Google Analytics represents that it does
      not collect any personal information, as it explains at
      https://developers.google.com/analytics/devguides/collection/gajs/cookie-usage.</span></p>
  <p><br></p>
  <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
      style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">How
      does Stokkup treat &ldquo;Do Not Track&rdquo; requests from a user&rsquo;s browser?</span></p>
  <p><br></p>
  <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
      style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Users
      may have the option of enabling &ldquo;Do Not Track&rdquo; in their browsers, which sends a signal to websites
      that the user does not want to be tracked across multiple websites. Stokkup does not track users across multiple
      websites, regardless of whether users have enabled &ldquo;Do Not Track.&rdquo;</span></p>
  <p><br></p>
  <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
      style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Does
      Stokkup use personal information for advertising purposes?</span></p>
  <p><br></p>
  <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
      style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Stokkup
      may use personal information, such as your email address, to provide you with information regarding Stokkup
      products and services if you have opted to receive newsletters or other communications from Stokkup. Stokkup does
      not share personal information with third-party advertisers.</span></p>
  <p><br></p>
  <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
      style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Under
      what circumstances does Stokkup disclose or transfer personal information to others ?</span></p>
  <p><br></p>
  <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
      style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">There
      are circumstances where we will transfer and/or disclose your personal information to third parties. Those
      circumstances, subject to the limits of applicable law, are as follows:</span></p>
  <p><br></p>
  <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
      style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Parent,
      Subsidiaries and Affiliates : Stokkup may transfer personal information to its parent, subsidiaries and corporate
      affiliates for purposes related to Stokkup&rsquo;s product offerings as such offerings may change and develop over
      time.</span></p>
  <p><br></p>
  <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
      style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">The
      Site Consultants and Service Providers : Stokkup transfers personal information to third-party service providers
      that may be hosting or housing data on behalf of Stokkup or otherwise acting on its behalf as a data center (such
      as providers of hosting, cloud, technology outsourcing and support services). Additionally, Stokkup may disclose
      personal information to the extent that our service providers require access to our databases, or the information
      contained in our databases, to service Stokkup and the Site. Note to residents of Canada: Personal information you
      provide in connection with your use of the Site may be processed and/or stored by Stokkup, its parent, affiliates,
      corporate partners, or third-party service providers in the United States.</span></p>
  <p><br></p>
  <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
      style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Legal
      Compulsion and Stokkup&rsquo;s Protection : Stokkup reserves the right to release personal information in
      accordance with applicable law in response to a subpoena or otherwise when we believe that the law requires us to
      do so, or when we reasonably believe it is necessary to protect and/or enforce the rights, property interests, or
      safety of Stokkup, our users or others.</span></p>
  <p><br></p>
  <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
      style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Reorganization
      or Sale of Stokkup : In the event that Stokkup is merged with or becomes part of another organization, or in the
      event that Stokkup is sold or it sells all or substantially all of its assets, or a brand or line of its business,
      the information you provide may be one of the transferred assets for use by the acquiring entity.</span></p>
  <p><br></p>
  <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
      style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">As
      Otherwise Allowed by Law : We may transfer personal information to third parties where we are expressly authorized
      by applicable law to do so.</span></p>
  <p><br></p>
  <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
      style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">How
      do you keep my personal information secure?</span></p>
  <p><br></p>
  <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
      style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Although
      we cannot guarantee that our or our vendors&rsquo; servers are impenetrable, we have attempted to establish and
      implement reasonable physical, electronic, and managerial procedures to safeguard and secure the information we
      collect online. Where we use outside vendors to collect, store, or process data, we attempt to use vendors who
      likewise also establish and implement reasonable procedures.</span></p>
  <p><br></p>
  <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
      style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Does
      the Site link to third party sites?</span></p>
  <p><br></p>
  <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
      style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">The
      Site may link to third-party sites including social media websites. When using third-party sites you should review
      the privacy policies of such parties&rsquo; sites to understand how they collect and use personal
      information.</span></p>
  <p><br></p>
  <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
      style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">How
      long do you keep my information?</span></p>
  <p><br></p>
  <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
      style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Stokkup
      keeps your personal information for as long as we deem it useful to use and for as long as applicable law allows
      us to maintain it.</span></p>
  <p><br></p>
  <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
      style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">How
      can I access and make changes to my personal information?</span></p>
  <p><br></p>
  <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
      style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Site
      users can change information in their profiles at any time by logging into their account through the Site. Site
      users have the right to request that their information be modified or deleted from our files. Subject to
      applicable law, technical feasibility, and Stokkup&rsquo;s reasonable need to maintain such information, you can
      change any of the personal information or other information that we maintain in your account profile. If at any
      time you want to view the personal information that we maintain regarding you, your personal information changes,
      you want to change the preferences associated with your account, or wish to remove some or all of the personal
      information or other information stored about you, you can, subject to the Terms of Use and this paragraph,
      correct, update, or remove the information and preferences you have shared with us by contacting us at
      info@stokkup.com or as directed below.</span></p>
  <p><br></p>
  <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
      style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">California
      Privacy Rights</span></p>
  <p><br></p>
  <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
      style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">California
      Civil Code Section 1798.83, also known as the &quot;Shine The Light&quot; law, permits our customers who are
      California residents to request and obtain from us once a year, free of charge, information about the personal
      information (if any) we disclosed to third-parties for direct marketing purposes in the preceding calendar year.
      If applicable, this information would include a list of the categories of personal information that was shared and
      the names and addresses of all third parties with which we shared information in the immediately preceding
      calendar year. If you are a California resident and would like to make such a request, please submit your request
      in writing to the contact address below.</span></p>
  <p><br></p>
  <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
      style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">How
      will I be notified of changes and updates to the Privacy Statement?</span></p>
  <p><br></p>
  <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
      style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Changes
      and updates to this Privacy Statement will be posted on the Site. Updated Privacy Statements are effective upon
      posting to the Site.</span></p>
  <p><br></p>
  <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
      style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Who
      do I contact about my rights under this Privacy Statement ?</span></p>
  <p><br></p>
  <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
      style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">If
      you have any comments, questions or concerns regarding this Privacy Statement or Stokkup&rsquo;s privacy practices
      at the Site, please contact us at Stokkup, Attn.: Privacy Officer, 1151 w 40th street Chicago IL 60609 or
      info@stokkup.com.</span></p>
</section>