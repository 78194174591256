import {BehaviorSubject, Subject} from "rxjs";
import {Injectable} from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class GlobalLoaderService {
  private loaderSubject$: Subject<boolean|string> = new BehaviorSubject('');
  public loaderState = this.loaderSubject$.asObservable();
  message = 'This may take a while, please wait...'

  show(progressMessage?: string) {
    this.loaderSubject$.next(progressMessage ?? true);
  }

  hide() {
    this.message = null
    this.loaderSubject$.next(false);
  }

  setMessage(message: string) {
    this.message = message
  }
}
