import { Component, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-login-base',
  templateUrl: './login-base.component.html',
  styleUrls: ['./login-base.component.scss']
})
export class LoginBaseComponent implements OnInit{ 
  screenWidth: any;

  constructor( ) { }

  ngOnInit(): void {
    this.screenWidth = window.innerWidth;
  }

  @HostListener('window:resize', ['$event'])

  onResize(event) {
    this.screenWidth = window.innerWidth;
  }
}
